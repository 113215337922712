import { FC, useEffect, useRef, useState } from 'react';
import { useMessageContext } from '../../state/context/MessageContext';
import { FlexCol, FlexRow, Paper } from '../../components/common/common';

import uPlot from 'uplot';
import 'uplot/dist/uPlot.min.css';
import { Button, Col, Divider, Empty, InputNumber, InputNumberProps, Radio, Row, Slider, Spin, Tooltip, Typography, message, Switch } from 'antd';
import { DeviceValue, RecordKey, newRecord, commitSensogramPartition, commitSensorPartition, SENSOR_NATURE } from '../../services/cache/idb';
import { DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC, DEFAULT_RAW_FPS, IDB_PARTITION_WINDOW_SIZE, PLOT_WINDOW_SIZE, DEFAULT_STORAGE_DECIMATED_FPS } from '../../utils/constants/constants';
import { ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY, ARYBALLE_COLOR_GRAY_DARK, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW, colorHexToRGBA, rowIdxToLetter, spotsgrid1dIndexTo2dCoordinates } from '../../utils/helpers/utils';
import { BoxPlotOutlined, BoxPlotTwoTone, BugOutlined, BugTwoTone, ForwardOutlined, LinkOutlined, LoadingOutlined, PushpinOutlined, PushpinTwoTone, QuestionCircleOutlined, VerticalAlignBottomOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { transpose, mean, standardDeviation, meanPairwiseDifference } from '../../components/analysis/utils';
import { aggregateSignature, sortSignature, normalizeL2, parseBiosensorsSignalMessagePayload } from '../../components/analysis/compute';
import { QuestonningResultWidget } from '../../components/widgets/Graph/questionningResultWidget';
import { loadSpotsGrid1D } from '../../services/cache/localStorage';
import { Link } from 'react-router-dom';
import { navigatorSupportsWebBle } from '../../services/ble/ble';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, encodePumpPower, parseBiosensorsSignalEvent, parseEventPayload } from '../../components/serial/csm';
import { WebBleNotSupportedWidget } from '../../components/widgets/BrowserNotSupported/webBleNotSupportedWidget';
import { v4 as uuidv4 } from 'uuid';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../../components/serial/constants';
import { RecordNameModalDialog } from './Recording/RecordNameModalDialog';
import SenseTab from './SenseComponents/SenseTab';
import DebugInfo from '../../components/widgets/Parameters/DebugInfos';
import SensorAggregation from './SenseParametersControl/SensorAggregation';
import ResetToZero from '../../components/widgets/Parameters/ResetToZero';
import ClearChart from './SenseParametersControl/ClearChart';
import PinLastRecognitionResult from './SenseParametersControl/PinLastRecognitionResult';
import GlobalParameters from '../../components/widgets/Parameters/GlobalParameters';
import DebugInfoComponenent from './SenseComponents/DebugInfoComponent';
import GlobalParametersComponent from './SenseComponents/GlobalParametersComponent';
import SensingButton from './SenseComponents/SensingButton';
import RecordingButton from './Recording/RecordingButton';
import SpotfileNotFound from '../../components/widgets/Spotfile/SpotfileNotFound';
import RecognitionButton from './Recognition/RecognitionButton';
import { pushSensorDataToRunner } from '../../services/api/runnerApi';
import { pushSliding } from '../../utils/helpers/array';
import { applyCorrections, calculateDecimatedMzis } from '../../components/analysis/mzi';

enum SenseMode {
  Recording,
  Questionning,
}

export const CsmSensePage: FC = () => {
  const { csmMessages, csmIsConnected, csmFwVersion, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  const [mziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null);

  const [mziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([]);
  const [fpsUplotData, setFpsUplotData] = useState<uPlot.AlignedData>([]);

  const mziTargetRef = useRef<HTMLDivElement>(null);
  const mziUplotRef = useRef<uPlot | null>(null);
  const mziTooltipRef = useRef<HTMLDivElement>(null);

  const fpsTargetRef = useRef<HTMLDivElement>(null);
  const fpsUplotRef = useRef<uPlot | null>(null);

  const firstMZIsRef = useRef<number[] | null>(null);

  const noizeLevelRef = useRef<number>(0);
  const isOdorPresentRef = useRef<boolean>(false);
  const odorPresenceThresholdLevelRef = useRef<number>(0);
  const maxOdorPresentValue = useRef<number>(0);
  const odorPresentStartTimestampRef = useRef<number>(0);
  const odorPresentStopTimestampRef = useRef<number>(0);
  const odorPresentLastRecognitionTimestampRef = useRef<number>(0);
  const signalEnvelopeMinRef = useRef<number>(0);
  const signalEnvelopeMaxRef = useRef<number>(0);
  const signalEnvelopeAvgRef = useRef<number>(0);

  const decimatedMZISeriesRef = useRef<number[][]>([]);
  const rawMZISeriesRef = useRef<number[][]>([]);

  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const rawTimestampSeriesRef = useRef<number[]>([]);

  const decimatedMZIPartitionSeriesRef = useRef<number[][]>([]);
  const decimatedTimestampPartitionSeriesRef = useRef<number[]>([]);

  const decimatedFpsTimeseriesRef = useRef<number[]>([]);
  const rawFpsTimeseriesRef = useRef<number[]>([]);

  const [rawFps, setRawFps] = useState<number>(0);
  const [decimatedFps, setDecimatedFps] = useState<number>(0);

  const decimatedHumidityPartitionSeriesRef = useRef<number[]>([]); // used only for partition storage
  const displayedHumiditySeriesRef = useRef<number[]>([]);
  const decimatedTemperatureSeriesRef = useRef<number[]>([]);

  const startTickRef = useRef<number>(0);
  const lastDecimationTickRef = useRef<number>(0);

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const recordKeyRef = useRef<RecordKey | null>(null);

  const [isSensing, setIsSensing] = useState<boolean>(true);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);

  const [shouldAggregate, setShouldAggregate] = useState<boolean>(true);
  const [showDebugInfo, setShowDebugInfo] = useState<boolean>(false);
  const [showGlobalParameters, setShowGlobalParameters] = useState<boolean>(false);
  const [shouldRedraw, setShouldRedraw] = useState<boolean>(false);
  const [pinLastQuestionningResult, setPinLastQuestionningResult] = useState<boolean>(true);

  const [senseMode, setSenseMode] = useState<SenseMode>(SenseMode.Recording);

  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  const [pumpPower, setPumpPower] = useState<number>(45);

  const [isGraphQlPushRunning, setIsGraphQlPushRunning] = useState(false);

  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));

  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
    // Aggregate MZIs by peptide
    let _aggregationIndicesMap: Record<number, number[]> = {};
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let aggKey = _spotsgrid1d[i];
      if (aggKey < 0) {
        continue;
      }
      if (_aggregationIndicesMap[aggKey] === undefined) {
        _aggregationIndicesMap[aggKey] = [];
      }
      _aggregationIndicesMap[aggKey].push(i);
    }
    setAggregatedIndicesMap(_aggregationIndicesMap);
    // console.log("sense page: _aggregationIndicesMap", _aggregationIndicesMap)
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetPumpPower,
          Payload: encodePumpPower(pumpPower),
        },
      });
    }, 500);

    // function for cleaning up previous iteration if this useEffect gets re-executed before the timeout
    return () => clearTimeout(timeOutId);
  }, [pumpPower]);

  useEffect(() => {
    if (csmMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }
    let t = Date.now();
    // console.log("sense page: acquiring mutex..")
    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        // console.log("sense page: acquired mutex in ", Date.now() - t, "ms")
        let nFramesOnOneMutexLock = 0;
        for (let message of csmMessages) {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('sense page: csm ble message is not a biosensors signal event', message.message);
            consumeCSMMessage(message.id);
            continue;
          } else {
            nFramesOnOneMutexLock++;
            consumeCSMMessage(message.id);

            // load the spotsgrid
            if (!currentSpotsgrid1d) {
              console.log('sense page: spotsgrid is empty');
              return;
            }

            let tick = startTickRef.current;
            // console.log("sense page: ts", ts)

            let mzis: number[] | null = parseBiosensorsSignalMessagePayload(message, csmFwVersion);
            tick = message.ts;
            if (mzis === null) {
              continue;
            }

            // push graphql if requested
            if (isGraphQlPushRunning) {
              pushSensorDataToRunner(mzis, hihValues);
            }

            rawMZISeriesRef.current.push(mzis);
            rawTimestampSeriesRef.current.push(tick);

            if (tick - lastDecimationTickRef.current < 1000 / DEFAULT_PLOT_DECIMATED_FPS) {
              continue;
            }
            lastDecimationTickRef.current = tick;
            pushSliding(decimatedTimestampSeriesRef.current, tick, PLOT_WINDOW_SIZE);
            // console.log('sense page: decimated timestamp timeseries', decimatedTimestampSeriesRef.current);

            // FPS computation
            // TODO move somewhere else
            let rawTimestampInterval = meanPairwiseDifference(rawTimestampSeriesRef.current);
            let rawFps = 1000 / rawTimestampInterval;
            setRawFps(rawFps);
            if (!isNaN(rawFps)) {
              pushSliding(rawFpsTimeseriesRef.current, rawFps, PLOT_WINDOW_SIZE);
            }
            rawTimestampSeriesRef.current = [];

            let decimatedTimestampInterval = meanPairwiseDifference(decimatedTimestampSeriesRef.current);
            let decimatedFps = 1000 / decimatedTimestampInterval;
            setDecimatedFps(decimatedFps);

            if (!isNaN(decimatedFps)) {
              pushSliding(decimatedFpsTimeseriesRef.current, decimatedFps, PLOT_WINDOW_SIZE);
            }

            let decimatedMzis = calculateDecimatedMzis(currentSpotsgrid1d, rawMZISeriesRef.current);
            rawMZISeriesRef.current = [];

            if (decimatedMzis === undefined) continue;

            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...decimatedMzis];
            }

            decimatedMzis = applyCorrections(decimatedMzis, firstMZIsRef.current, currentSpotsgrid1d, false, undefined, null, hihValues);

            pushSliding(decimatedMZISeriesRef.current, decimatedMzis, PLOT_WINDOW_SIZE);
            pushSliding(decimatedHumidityPartitionSeriesRef.current, hihValues.humidity, PLOT_WINDOW_SIZE);
            pushSliding(displayedHumiditySeriesRef.current, hihValues.humidity, PLOT_WINDOW_SIZE);
            pushSliding(decimatedTemperatureSeriesRef.current, hihValues.temperature, PLOT_WINDOW_SIZE);

            decimatedTimestampPartitionSeriesRef.current.push(tick);
            decimatedMZIPartitionSeriesRef.current.push(decimatedMzis); // save non-aggreated mzis regardless of shouldAggregate

            if (decimatedMZIPartitionSeriesRef.current.length >= IDB_PARTITION_WINDOW_SIZE) {
              if (isRecording && recordKeyRef.current !== null) {
                // average decimate decimatedMZIPartitionSeriesRef by 2 for storage
                // (storage decimation is half the plotting one)
                let storageDecimatedMZIPartitionSeries: number[][] = [];
                let storageDecimatedTimestampPartitionSeries: number[] = [];
                let storageDecimatedHumidityPartitionSeries: number[] = [];
                let storageDecimatedTemperaturePartitionSeries: number[] = [];

                let storageDecimationFactor = Math.floor(DEFAULT_PLOT_DECIMATED_FPS / DEFAULT_STORAGE_DECIMATED_FPS);
                for (let i = 0; i < decimatedMZIPartitionSeriesRef.current.length; i += storageDecimationFactor) {
                  let storageDecimatedMzis: number[] = [];
                  for (let j = 0; j < currentSpotsgrid1d.length; j++) {
                    let sum = 0;
                    for (let ii = 0; ii < storageDecimationFactor; ii++) {
                      sum += decimatedMZIPartitionSeriesRef.current[i + ii][j];
                    }
                    storageDecimatedMzis[j] = sum / storageDecimationFactor;
                  }
                  storageDecimatedMZIPartitionSeries.push(storageDecimatedMzis);
                  // timestamps are just subsampled (not averaged)
                  storageDecimatedTimestampPartitionSeries.push(decimatedTimestampPartitionSeriesRef.current[i]);
                  // same for humidity and temperature series
                  storageDecimatedHumidityPartitionSeries.push(decimatedHumidityPartitionSeriesRef.current[i]);
                  storageDecimatedTemperaturePartitionSeries.push(decimatedTemperatureSeriesRef.current[i]);
                }
                commitSensogramPartition(recordKeyRef.current, storageDecimatedMZIPartitionSeries, storageDecimatedTimestampPartitionSeries)
                  .then(() => {
                    console.log('sense page: saved partition');
                  })
                  .catch((e: any) => {
                    console.log('sense page: could not save partition', e);
                  });
                commitSensorPartition(recordKeyRef.current, storageDecimatedHumidityPartitionSeries, storageDecimatedTimestampPartitionSeries, SENSOR_NATURE.Humidity)
                  .then(() => {
                    console.log('sense page: saved partition');
                  })
                  .catch((e: any) => {
                    console.log('sense page: could not save partition', e);
                  });
                commitSensorPartition(recordKeyRef.current, storageDecimatedTemperaturePartitionSeries, storageDecimatedTimestampPartitionSeries, SENSOR_NATURE.Temperature)
                  .then(() => {
                    console.log('sense page: saved partition');
                  })
                  .catch((e: any) => {
                    console.log('sense page: could not save partition', e);
                  });
              }
              decimatedMZIPartitionSeriesRef.current = [];
              decimatedTimestampPartitionSeriesRef.current = [];
              decimatedHumidityPartitionSeriesRef.current = [];
              decimatedTemperatureSeriesRef.current = [];
            }

            let seriesLabels: number[] = [];
            if (shouldAggregate) {
              seriesLabels = Object.keys(aggregatedIndicesMap).map((aggKey) => parseInt(aggKey));
            } else {
              seriesLabels = [...currentSpotsgrid1d];
            }
            let nDims = seriesLabels.length;

            // build mzi uplot data
            let X = decimatedTimestampSeriesRef.current.map((ts) => ts / 1000);

            // aggregate mzis timeseries (frame by frame) if needed
            let finalMZIsSeries: number[][] = [];
            if (shouldAggregate) {
              for (let j = 0; j < decimatedMZISeriesRef.current.length; j++) {
                let finalMZIs: number[] = [];
                for (let aggKey in aggregatedIndicesMap) {
                  let aggIndices = aggregatedIndicesMap[aggKey];
                  let values: number[] = [];

                  // Collect values for the current group
                  for (let i = 0; i < aggIndices.length; i++) {
                    values.push(decimatedMZISeriesRef.current[j][aggIndices[i]]);
                  }

                  // Sort the values to calculate the median
                  values.sort((a, b) => a - b);

                  let median: number;
                  let len = values.length;

                  if (len % 2 === 0) {
                    // Even number of elements, average the two middle values
                    median = (values[len / 2 - 1] + values[len / 2]) / 2;
                  } else {
                    // Odd number of elements, take the middle value
                    median = values[Math.floor(len / 2)];
                  }

                  finalMZIs.push(median);
                }
                finalMZIsSeries.push(finalMZIs);
              }
            } else {
              finalMZIsSeries = decimatedMZISeriesRef.current;
            }

            if (finalMZIsSeries.length < 2) {
              continue;
            }

            //
            let lastFrame: number[] = finalMZIsSeries[finalMZIsSeries.length - 1];
            let lastFrameSum: number = 0;
            signalEnvelopeMinRef.current = 1e6;
            signalEnvelopeMaxRef.current = -1e6;
            for (let i = 0; i < lastFrame.length; i++) {
              if (lastFrame[i] < signalEnvelopeMinRef.current) {
                signalEnvelopeMinRef.current = lastFrame[i];
              }
              if (lastFrame[i] > signalEnvelopeMaxRef.current) {
                signalEnvelopeMaxRef.current = lastFrame[i];
              }
              lastFrameSum += lastFrame[i];
            }
            signalEnvelopeAvgRef.current = lastFrameSum / lastFrame.length;
            let previousFrameMean: number = mean(finalMZIsSeries[finalMZIsSeries.length - 2]);

            let avgSeries: number[] = [];
            for (let i = 0; i < finalMZIsSeries.length; i++) {
              avgSeries.push(mean(finalMZIsSeries[i]));
            }

            if (!isOdorPresentRef.current && noizeLevelRef.current > 0 && signalEnvelopeAvgRef.current > noizeLevelRef.current) {
              odorPresenceThresholdLevelRef.current = mean([previousFrameMean, signalEnvelopeAvgRef.current]);
              odorPresentStartTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
              odorPresentStopTimestampRef.current = 0;
              odorPresentLastRecognitionTimestampRef.current = Date.now();
              isOdorPresentRef.current = true;
            }

            if (isOdorPresentRef.current && signalEnvelopeAvgRef.current < Math.max(maxOdorPresentValue.current * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorPresenceThresholdLevelRef.current)) {
              isOdorPresentRef.current = false;
              maxOdorPresentValue.current = 0;
              odorPresenceThresholdLevelRef.current = 0;
              odorPresentStopTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
              if (!pinLastQuestionningResult) {
                setQuestionningSignature(null);
              } else {
                constructSignatureAndRecognize();
              }
            }

            if (!isOdorPresentRef.current) {
              let noizeSeries = avgSeries.slice(-4 * DEFAULT_PLOT_DECIMATED_FPS, -1 * DEFAULT_PLOT_DECIMATED_FPS);
              noizeLevelRef.current = mean(noizeSeries) + standardDeviation(noizeSeries) * 12;
            }

            if (isOdorPresentRef.current) {
              maxOdorPresentValue.current = Math.max(maxOdorPresentValue.current, signalEnvelopeAvgRef.current);
            }

            if (isOdorPresentRef.current && Date.now() - odorPresentLastRecognitionTimestampRef.current > 250) {
              constructSignatureAndRecognize();
              odorPresentLastRecognitionTimestampRef.current = Date.now();
            }

            // build uplot options
            if (mziUplotOptions === null) {
              const opts: uPlot.Options = {
                id: `uplot-chart-mmi`,
                width: 0,
                height: 0,
                padding: [0, 50, 0, 0],
                legend: {
                  show: false,
                },
                scales: {
                  y: {
                    range: (u, min, max) => {
                      const _max = max > 1 ? max : 1;
                      const _min = min < -1 ? min : -1;
                      return [_min, _max];
                    },
                  },
                  RH: {
                    range: (u, min, max) => {
                      console.log('min', min);
                      console.log('max', max);
                      return [min - 1, max + 1];
                    },
                  },
                },
                axes: [
                  {}, // X axis
                  {
                    scale: 'y',
                    label: 'Phase Shift (rad)',
                  },
                  {
                    side: 1,
                    scale: 'RH',
                    label: 'Humidity (%RH)',
                  },
                ],
                pxAlign: 0,
                series: [
                  {},
                  // sensogram series
                  ...seriesLabels.map((spotInt) => {
                    let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
                    let peptideInt = spotInt;
                    if (peptideInt < 0) {
                      peptideInt *= -1;
                    }
                    let peptideStr = spotInt.toString();
                    if (peptideStr.length === 3 && peptideStr[2] === '4') {
                      peptideInt = parseInt(peptideStr.slice(0, 2));
                    }
                    if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
                      color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
                    }
                    let label = peptideInt.toString();
                    return {
                      show: spotInt >= 1,
                      spanGaps: false,
                      label: label,
                      stroke: color,
                      width: 2,
                    } as uPlot.Series;
                  }),
                  // Avg series
                  {
                    show: true,
                    spanGaps: false,
                    label: 'Avg',
                    stroke: 'rgba(0,0,0,0.7)',
                    width: 2,
                  } as uPlot.Series,
                  // humidity series
                  {
                    scale: 'RH',
                    label: 'Humidity',
                    stroke: 'rgba(0,128,255,0.7)',
                    width: 2,
                    dash: [10, 5],
                  } as uPlot.Series,
                ],
                hooks: {
                  setSeries: [
                    (u, seriesIdx) => {
                      try {
                        // console.log("sense page: set series", seriesIdx)
                        if (mziTooltipRef.current === null) {
                          return;
                        }
                        if (seriesIdx === null) {
                          return;
                        }
                        let seriesLabel = u.series[seriesIdx].label;
                        if (seriesLabel === undefined) {
                          return;
                        }
                        let peptideInt = parseInt(seriesLabel);
                        let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
                        if (seriesLabel === 'Avg') {
                          color = 'rgba(0,0,0,0.7)';
                        }
                        if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
                          color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
                        }
                        let tooltip = seriesLabel;
                        if (!shouldAggregate) {
                          let [row, col] = spotsgrid1dIndexTo2dCoordinates(seriesIdx - 1);
                          let rowStr = rowIdxToLetter(row);
                          tooltip += ` [${rowStr}${col}]`;
                        }
                        mziTooltipRef.current.innerHTML = `<b>${tooltip}</b>`;
                        mziTooltipRef.current.style.backgroundColor = color;
                        mziTooltipRef.current.style.color = 'white';
                        mziTooltipRef.current.style.border = '1px solid white';
                        mziTooltipRef.current.style.borderRadius = '5px';
                        mziTooltipRef.current.style.padding = '5px';
                      } catch (e) {
                        console.log('sense page: set series error', e);
                      }
                    },
                  ],
                  draw: [
                    // draw horizontal line at noize level
                    (u) => {
                      const ctx = u.ctx;
                      const x0 = u.bbox.left;
                      const x1 = u.bbox.left + u.bbox.width;
                      const y = u.valToPos(noizeLevelRef.current, 'y', true);
                      ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY, 1);
                      ctx.lineWidth = 2;
                      ctx.setLineDash([20, 5, 5, 5]);
                      ctx.beginPath();
                      ctx.moveTo(x0, y);
                      ctx.lineTo(x1, y);
                      ctx.stroke();
                    },
                    // draw signal envelope and indicate average
                    (u) => {
                      const ctx = u.ctx;
                      const yMax = u.valToPos(signalEnvelopeMaxRef.current, 'y', true);
                      const yAvg = u.valToPos(signalEnvelopeAvgRef.current, 'y', true);
                      const yMin = u.valToPos(signalEnvelopeMinRef.current, 'y', true);
                      let txt = signalEnvelopeAvgRef.current.toFixed(2);
                      let txtWidth = ctx.measureText(txt).width;
                      const x = u.bbox.left + u.bbox.width + 20;

                      ctx.font = '24px sans-serif';
                      ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1);
                      ctx.fillText(signalEnvelopeAvgRef.current.toFixed(2), x + txtWidth + 20, yAvg);

                      // ctx.canvas.width = ctx.canvas.width + txtWidth + 20

                      let bracketWidth = 5;
                      let pointerWidth = 10;

                      ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1);
                      ctx.lineWidth = 2;
                      ctx.setLineDash([1, 0]);
                      ctx.beginPath();
                      ctx.moveTo(x, yMax);
                      ctx.lineTo(x + bracketWidth, yMax);
                      ctx.lineTo(x + bracketWidth, yAvg);
                      ctx.lineTo(x + bracketWidth + pointerWidth, yAvg);
                      ctx.moveTo(x + bracketWidth, yAvg);
                      ctx.lineTo(x + bracketWidth, yMin);
                      ctx.lineTo(x, yMin);
                      ctx.stroke();
                    },
                    // draw odor presence threshold
                    // (u) => {
                    //     if (!isOdorPresentRef.current) {
                    //         return
                    //     }
                    //     const ctx = u.ctx
                    //     const x0 = u.bbox.left
                    //     const x1 = u.bbox.left + u.bbox.width
                    //     const y = u.valToPos(odorPresenceThresholdLevelRef.current, "y", true)
                    //     ctx.strokeStyle = "rgba(255, 0, 0, 1)"
                    //     ctx.lineWidth = 2
                    //     ctx.setLineDash([10, 5]);
                    //     ctx.beginPath()
                    //     ctx.moveTo(x0, y)
                    //     ctx.lineTo(x1, y)
                    //     ctx.stroke()
                    // },
                    // draw odor presence "corridor"
                    (u) => {
                      if (!isOdorPresentRef.current) {
                        return;
                      }
                      const ctx = u.ctx;
                      const x0 = u.bbox.left;
                      const x1 = u.bbox.left + u.bbox.width;
                      const y0 = u.valToPos(Math.max(maxOdorPresentValue.current, signalEnvelopeMaxRef.current), 'y', true);
                      const y1 = u.valToPos(Math.max(maxOdorPresentValue.current * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorPresenceThresholdLevelRef.current), 'y', true);
                      ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2);
                      ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1);
                      ctx.lineWidth = 3;
                      ctx.setLineDash([10, 5]);
                      ctx.beginPath();
                      ctx.moveTo(x0, y0);
                      ctx.lineTo(x1, y0);
                      ctx.moveTo(x1, y1);
                      ctx.lineTo(x0, y1);
                      ctx.fillRect(x0, y1, x1 - x0, y0 - y1);
                      ctx.stroke();
                    },
                    // draw odor presence start and stop timestamps
                    (u) => {
                      const ctx = u.ctx;
                      ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1);
                      ctx.lineWidth = 3;
                      ctx.setLineDash([10, 5]);
                      const yBot = u.bbox.top + u.bbox.height;
                      const yTop = u.bbox.top;
                      if (odorPresentStartTimestampRef.current > 0) {
                        const startX = u.valToPos(odorPresentStartTimestampRef.current / 1000, 'x', true);
                        ctx.beginPath();
                        ctx.moveTo(startX, yBot);
                        ctx.lineTo(startX, yTop);
                        ctx.stroke();
                      }
                      if (odorPresentStopTimestampRef.current > 0) {
                        const stopX = u.valToPos(odorPresentStopTimestampRef.current / 1000, 'x', true);
                        ctx.beginPath();
                        ctx.moveTo(stopX, yBot);
                        ctx.lineTo(stopX, yTop);
                        ctx.stroke();
                      }
                      if (odorPresentStartTimestampRef.current > 0 && odorPresentStopTimestampRef.current > 0) {
                        const startX = u.valToPos(odorPresentStartTimestampRef.current / 1000, 'x', true);
                        const stopX = u.valToPos(odorPresentStopTimestampRef.current / 1000, 'x', true);
                        ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2);
                        ctx.fillRect(startX, yBot, stopX - startX, yTop - yBot);
                      }
                    },
                  ],
                },
                focus: {
                  alpha: 0.3,
                },
                cursor: {
                  focus: {
                    prox: 10,
                  },
                },
              };
              // console.log("sense page: uplot options", opts)
              setMziUplotOptions(opts);
            }

            // transpose mzisTimeseriesRef.current into Ys
            let Ys = [];
            for (let i = 0; i < nDims; i++) {
              let Y = [];
              for (let j = 0; j < finalMZIsSeries.length; j++) {
                Y[j] = finalMZIsSeries[j][i];
              }
              Ys.push(Y);
            }
            Ys.push(avgSeries);

            Ys.push(displayedHumiditySeriesRef.current);
            let data: uPlot.AlignedData = [X, ...Ys];
            setMziUplotData(data);
            setFpsUplotData([X, rawFpsTimeseriesRef.current, decimatedFpsTimeseriesRef.current, X.map(() => DEFAULT_RAW_FPS), X.map(() => DEFAULT_PLOT_DECIMATED_FPS)]);
            continue;
          }
        }
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    if (mziUplotOptions !== null && mziUplotData !== null && mziTargetRef.current !== null) {
      if (mziUplotRef.current === null || shouldRedraw) {
        if (mziUplotRef.current !== null) {
          mziUplotRef.current.destroy();
          mziUplotRef.current = null;
        }
        let plot = new uPlot(mziUplotOptions, mziUplotData, mziTargetRef.current);
        plot.setSize({
          width: mziTargetRef.current.clientWidth,
          height: mziTargetRef.current.clientHeight,
        });
        mziUplotRef.current = plot;
        if (shouldRedraw) {
          setShouldRedraw(false);
        }
      } else {
        mziUplotRef.current.setData(mziUplotData);
        mziUplotRef.current.setSize({
          width: mziTargetRef.current.clientWidth,
          height: mziTargetRef.current.clientHeight,
        });
      }
    } else {
      console.log('sense page: could not create mzi uplot', mziUplotOptions, mziUplotData, mziTargetRef.current);
    }
  }, [mziUplotOptions, mziUplotData, mziTargetRef.current]);

  useEffect(() => {
    if (fpsTargetRef.current !== null && fpsUplotData !== null) {
      if (fpsUplotRef.current === null || shouldRedraw) {
        if (fpsUplotRef.current !== null) {
          fpsUplotRef.current.destroy();
          fpsUplotRef.current = null;
        }
        let opts: uPlot.Options = {
          id: `uplot-chart-fps`,
          width: 0,
          height: 0,
          legend: {
            show: false,
          },
          series: [
            {},
            {
              show: true,
              spanGaps: false,
              label: 'Raw FPS',
              stroke: 'red',
              value: (_, rawValue: number) => (rawValue !== null ? rawValue.toFixed(2) : 'N/A'),
            },
            {
              show: true,
              spanGaps: false,
              label: 'Decimated FPS',
              stroke: 'blue',
              value: (_, rawValue: number) => (rawValue !== null ? rawValue.toFixed(2) : 'N/A'),
            },
            {
              show: true,
              stroke: 'red',
              dash: [10, 5],
            },
            {
              show: true,
              stroke: 'blue',
              dash: [10, 5],
            },
          ],
        };
        let plot = new uPlot(opts, fpsUplotData, fpsTargetRef.current);
        plot.setSize({
          width: fpsTargetRef.current.clientWidth,
          height: fpsTargetRef.current.clientHeight,
        });
        fpsUplotRef.current = plot;
      } else {
        fpsUplotRef.current.setData(fpsUplotData);
        fpsUplotRef.current.setSize({
          width: fpsTargetRef.current.clientWidth,
          height: fpsTargetRef.current.clientHeight,
        });
      }
    } else {
      // console.log("sense page: could not create fps uplot", fpsUplotData, fpsTargetRef.current)
    }
  }, [fpsUplotData, fpsTargetRef.current]);

  useEffect(() => {
    const cleanup = () => {
      console.info('sense page: destroying uplot refs');
      if (mziUplotRef.current !== null) {
        mziUplotRef.current.destroy();
        mziUplotRef.current = null;
      }
      setMziUplotOptions(null);
      setMziUplotData([]);

      if (fpsUplotRef.current !== null) {
        fpsUplotRef.current.destroy();
        fpsUplotRef.current = null;
      }
      setFpsUplotData([]);
    };
    cleanup();
    return cleanup;
  }, []);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  const constructSignatureAndRecognize = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC;
    }
    let sectionMZIs = decimatedMZISeriesRef.current.slice(idxStart);
    let sectionMZIsSpans = transpose(sectionMZIs);

    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      if (sensorInt >= 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);

    setQuestionningSignature(normalizedSortedAggregatedSignature);
    setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
  };

  const onClickReset = () => {
    firstMZIsRef.current = null;
  };

  if (!navigatorSupportsWebBle()) {
    return <WebBleNotSupportedWidget />;
  }

  if (!csmIsConnected) {
    console.log('sense page: refkit is not connected. Redirecting to connect page');
    return null;
  }
  if (isLoading) {
    return (
      <FlexCol style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Spin size="large" />
      </FlexCol>
    );
  }

  if (currentSpotsgrid1d === null) {
    return <SpotfileNotFound />;
  }

  return (
    <FlexCol style={{ justifyContent: 'center', alignItems: 'start', width: '100%' }}>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          <SenseTab isRecording={isRecording} senseMode={senseMode} setSenseMode={setSenseMode} />

          <FlexRow style={{ width: '100%', justifyContent: 'end', alignItems: 'center', gap: 5 }}>
            <div ref={mziTooltipRef}></div>
            <DebugInfo showDebugInfo={showDebugInfo} setShowDebugInfo={setShowDebugInfo} />
            <GlobalParameters showGlobalParameters={showGlobalParameters} setShowGlobalParameters={setShowGlobalParameters} />
            <SensorAggregation shouldAggregate={shouldAggregate} setMziUplotOptions={setMziUplotOptions} setMziUplotData={setMziUplotData} setShouldAggregate={setShouldAggregate} setShouldRedraw={setShouldRedraw} />
            <ResetToZero firstMZIsRef={firstMZIsRef} onClickReset={onClickReset} />
            <ClearChart decimatedMZISeriesRef={decimatedMZISeriesRef} decimatedTimestampSeriesRef={decimatedTimestampSeriesRef} displayedHumiditySeriesRef={displayedHumiditySeriesRef} />
            <PinLastRecognitionResult pinLastQuestionningResult={pinLastQuestionningResult} setPinLastQuestionningResult={setPinLastQuestionningResult} />
          </FlexRow>

          {/* MZI Chart */}
          <Row
            gutter={[10, 10]}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Col xs={24} lg={senseMode === SenseMode.Recording ? 24 : 12}>
              <FlexRow
                style={{
                  width: '100%',
                  height: '100%',
                  padding: isRecording ? 3 : 0,
                  borderRadius: 10,
                  backgroundColor: isRecording ? 'red' : 'transparent',
                }}
              >
                <Paper
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 300,
                      maxHeight: '70vh',
                    }}
                    ref={mziTargetRef}
                  />
                </Paper>
              </FlexRow>
            </Col>
            <Col xs={24} lg={12}>
              {senseMode === SenseMode.Questionning && (
                <FlexRow
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <QuestonningResultWidget signature={questionningSignature} spotsgrid1d={questionningSpotsgrid1d} />
                </FlexRow>
              )}
            </Col>
          </Row>
          {/* Recording controls*/}
          <Row gutter={[5, 5]} style={{ width: '100%' }}>
            <Col xs={24} md={12}>
              <SensingButton isSensing={isSensing} setIsSensing={setIsSensing} />
            </Col>
            <Col xs={24} md={12}>
              <RecordingButton
                senseMode={senseMode}
                recordKeyRef={recordKeyRef}
                decimatedMZIPartitionSeriesRef={decimatedMZIPartitionSeriesRef}
                decimatedTimestampPartitionSeriesRef={decimatedTimestampPartitionSeriesRef}
                decimatedHumidityPartitionSeriesRef={decimatedHumidityPartitionSeriesRef}
                decimatedTemperatureSeriesRef={decimatedTemperatureSeriesRef}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                deviceValue={deviceValue}
              />
              <RecognitionButton senseMode={senseMode} constructSignatureAndRecognize={constructSignatureAndRecognize} />
            </Col>
          </Row>

          <GlobalParametersComponent showGlobalParameters={showGlobalParameters} pumpPower={pumpPower} setPumpPower={setPumpPower} isGraphQlPushRunning={isGraphQlPushRunning} setIsGraphQlPushRunning={setIsGraphQlPushRunning} />
          <DebugInfoComponenent showDebugInfo={showDebugInfo} rawFps={rawFps} decimatedFps={decimatedFps} hihValues={hihValues} fpsTargetRef={fpsTargetRef} />
        </>
      )}
    </FlexCol>
  );
};
