import { loadCurrentDeltaHumidityValue, loadHumidityCalibrant, loadRawSignatureSize64 } from '../../services/cache/localStorage';
import { instanceOfModelType, ModelType } from '../../utils/helpers/byteio/model';
import { aggregateSignature, correctHumidity, correctHumidityWithPositions, euclidianDistance, normalizeL2, sortSignature } from './compute';
import { GenericModel, ModelCategory, OBJECTIVE_COMPARISON_DIFFERENT, OBJECTIVE_COMPARISON_SIMILAR } from './definitions';
import { isInsideEllipse } from './ellipse';
import { pcaTransform } from './pca';
import { euclideanDistance, mean } from './utils';

/*
Aryballe custom classificator
A point is a projection of a signature on a 2D space (PCA or DC+PCA)
If point is inside one of the optimized (scaled) ellipses -> classified as this group
If point outside any ellipse -> unknown
If point is inside >1 ellipses -> group assigned as least euclidean distance between these groups' barycenters
*/
export const classifyPoint = (groupedScaledEllipses: Record<string, number[]>, point: [number, number]): string => {
  const results: string[] = [];
  Object.entries(groupedScaledEllipses).forEach(([group, ellipse]) => {
    if (isInsideEllipse(ellipse, point)) {
      results.push(group);
    }
  });
  const centroids = Object.entries(groupedScaledEllipses).map(([group, [cx, cy, a, b, theta]]) => ({ group, centroid: [cx, cy] }));
  let name: string = 'unknown';
  if (results.length === 1) {
    name = results[0];
  } else if (results.length > 1) {
    const centroidDistances = centroids.map(({ group, centroid }) => ({ group, dist: euclideanDistance(point, centroid) }));

    var closestGroup: string = centroidDistances[0].group;
    var closestDistance: number = centroidDistances[0].dist;

    centroidDistances.slice(1).forEach(({ group, dist }) => {
      if (dist < closestDistance) {
        closestGroup = group;
      }
    });
    name = closestGroup;
  }
  return name;
};

// signature should be of the same form as used in the model creation
// e.g. negative spots excluded, aggregated, sorted and normalized
//
// returns [group, point]
export const classifySignature = (groupedScaledEllipses: Record<string, number[]>, pcaEigenvectors: number[][], signature: number[]): [string, [number, number]] => {
  const points = pcaTransform([signature], pcaEigenvectors, 2);
  let point: [number, number] = [points[0][0], points[0][1]];
  return [classifyPoint(groupedScaledEllipses, point), point];
};

export const classifySignatureComparison = (referenceSignatures: number[][], questionningSignature: number[], distanceThreshold: number = 0.015): string => {
  console.log(
    'ref signatures are',
    referenceSignatures.map((x) => x.map((y) => y.toFixed(2)))
  );

  let avgSig: number[] = [];
  for (let i = 0; i < referenceSignatures[0].length; i++) {
    avgSig.push(mean(referenceSignatures.map((x) => x[i])));
  }
  console.log(
    'mean ref signature is',
    avgSig.map((x) => x.toFixed(2))
  );

  let distances = referenceSignatures.map((refSig) => euclideanDistance(refSig, questionningSignature));
  let result = mean(distances);
  console.log(
    'distances between ref and questionning signatures',
    distances.map((x) => x.toFixed(2))
  );
  console.log('mean distance between ref and questionning signature', result.toFixed(2));

  if (result > distanceThreshold) {
    return OBJECTIVE_COMPARISON_DIFFERENT;
  } else {
    return OBJECTIVE_COMPARISON_SIMILAR;
  }
};

export const classifyIntensityComparison = (referenceIntensities: number[], questionningSignature: number[], intensityComparisonThreshold: number = 0.1): string => {
  let meanRefIntensity = mean(referenceIntensities);
  let questionningIntensity = mean(questionningSignature);

  console.log(
    'reference intensities are ',
    referenceIntensities.map((x) => x.toFixed(2))
  );
  console.log('mean reference intensity is', meanRefIntensity.toFixed(2));

  let relativeDifference = Math.abs(meanRefIntensity - questionningIntensity) / meanRefIntensity;
  console.log('relative difference between ref and questionning intensities ', relativeDifference.toFixed(2));

  if (relativeDifference > intensityComparisonThreshold) {
    return OBJECTIVE_COMPARISON_DIFFERENT;
  } else {
    return OBJECTIVE_COMPARISON_SIMILAR;
  }
};

export const classifySignatureRuleBased = (currentModel: GenericModel, questionningSignature: number[], questionningSpotsgrid1d: number[]): [string, [number, number], number] => {
  let point: [number, number] = [0, 0];

  if (currentModel.projection_axis && currentModel.metadata.type !== ModelCategory.ChemicalPrediction) {
    // apply PCA to get transformed signature
    const points = pcaTransform([questionningSignature], currentModel.projection_axis, 2);
    point = [points[0][0], points[0][1]];
  }


  let rawSignatureMean = Math.abs(mean(questionningSignature));
  console.log('computing ..., raw is', questionningSignature, rawSignatureMean);
  let correctedSignatureMean: number;
  let distance = 0;
  let humidityCalibrant = loadHumidityCalibrant()
  let spotgrid = humidityCalibrant?.spotsgrid;

  let values: { [key: string]: number } = {};

  let ratioComputed: Record<string, number> = {};


  if (spotgrid && humidityCalibrant?.signature) {
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < spotgrid.length; i++) {
      let sensorInt = spotgrid[i];
      if (sensorInt >= 1) {
        excludedSignature.push(humidityCalibrant?.signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }
    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;
    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    // console.log('spotgrid', sortedFinalSpotsgrid1d);

    console.log('humidity calibrant is ', sortedFinaleSignature);

    // l2 signature humidty calibrant
    if (humidityCalibrant) {


      // check if delta H is positive or negative to compare the distance

      let deltaH = loadCurrentDeltaHumidityValue();
      console.log('deltaH is', deltaH);
      if (deltaH !== null && deltaH > 0) {
        // compute distance to current humiodity calibrant
        // L2 signature current
        // console.log('deltaH is positive');
        let normalizedSignature_current = normalizeL2(questionningSignature)
        let normalizedHumidityCalibrant = normalizeL2(sortedFinaleSignature)
        // console.log('Signature_current ', questionningSignature.map(value => value.toFixed(2)));
        // console.log('HumidityCalibrant', sortedFinaleSignature.map(value => value.toFixed(2)));
        // console.log('spotgrid', spotgrid);
        distance = 100 * euclidianDistance(normalizedSignature_current, normalizedHumidityCalibrant)

      }

      else if (deltaH !== null && deltaH < 0 && mean(questionningSignature) < 0) {
        // change signature to compute the distance 
        // console.log('deltaH is negative and mean raw siganture is', mean(questionningSignature));
        // console.log('Signature_current ', questionningSignature.map(value => -value));
        // console.log('HumidityCalibrant', sortedFinaleSignature.map(value => value));
        let normalizedSignature_current = normalizeL2(questionningSignature.map(value => -value)) // revert 
        let normalizedHumidityCalibrant = normalizeL2(sortedFinaleSignature)
        // console.log('normalizedSignature_current', normalizedSignature_current);
        // console.log('normalizedHumidityCalibrant', normalizedHumidityCalibrant);
        distance = 100 * euclidianDistance(normalizedSignature_current, normalizedHumidityCalibrant)

      }
      else if (deltaH !== null && deltaH < 0 && mean(questionningSignature) > 0) {
        // change signature to compute the distance 
        // console.log('deltaH is negative and mean raw siganture is', mean(questionningSignature));


        let normalizedSignature_current = normalizeL2(questionningSignature)
        let normalizedHumidityCalibrant = normalizeL2(sortedFinaleSignature)
        distance = 100 * euclidianDistance(normalizedSignature_current, normalizedHumidityCalibrant)

      }
      // console.log('distance is', distance);
      // compute corrected signature : 
      if (deltaH !== null) {

        let correctedSignatureMedianThenCorrected = correctHumidity(questionningSignature, deltaH, sortedFinalSpotsgrid1d, humidityCalibrant)
        console.log('medianThenCorrect', correctedSignatureMedianThenCorrected.map(elt => elt.toFixed(2)));
      }
      let raw64signature = loadRawSignatureSize64()
      if (deltaH !== null && raw64signature !== null) {
        // console.log('raw64signature', raw64signature, 'spotgrid', spotgrid, 'humidityCalibrant', humidityCalibrant);
        let correctedSignature = correctHumidityWithPositions(raw64signature, deltaH, spotgrid, humidityCalibrant);
        // console.log('correctedSignature size 64', correctedSignature.map(elt => elt.toFixed(2)));


        let excludedSignature: number[] = [];
        let excludedSpotsgrid1d: number[] = [];
        for (let i = 0; i < spotgrid.length; i++) {
          let sensorInt = spotgrid[i];
          if (sensorInt >= 1) {
            excludedSignature.push(correctedSignature[i]);
            excludedSpotsgrid1d.push(sensorInt);
          }
        }
        let finalSignature: number[] = [];
        let finalSpotsgrid1d: number[] = [];

        // always aggregate by common spot name
        let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
        finalSignature = aggregatedSignature;
        finalSpotsgrid1d = aggregatedSpotsgrid1d;
        let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
        // console.log('spotgrid', sortedFinalSpotsgrid1d);

        console.log('aggregated corrected non normalized signature is ', sortedFinaleSignature.map(elt => elt.toFixed(2)));

        let normalizedCorrectedSignatureCurrent = normalizeL2(sortedFinaleSignature)
        // console.log('final corected L2 signature is', normalizedCorrectedSignatureCurrent.map(elt => elt.toFixed(2)), 'spotgri1D sorted', sortedFinalSpotsgrid1d);
        // normalize L2 current corrected Signature

        correctedSignatureMean = mean(sortedFinaleSignature);

        function computeSpotRatios(spots: Record<string, number>): Record<string, number> {
          const spot1Value = spots["spot1"];
          if (spot1Value === undefined || spot1Value === 0) {
            throw new Error("spot1 is missing or has a value of 0, division not possible.");
          }

          // const ratioComputed: Record<string, number> = {};

          for (const [key, value] of Object.entries(spots)) {
            if (key !== "spot1") {
              ratioComputed[key] = value / spot1Value;
            }
          }

          return ratioComputed;
        }

        // let values: { [key: string]: number } = {};
        questionningSpotsgrid1d.forEach((spot, idxspot) => (values['spot' + spot] = normalizedCorrectedSignatureCurrent[idxspot]));
        console.log('corrected values is ', values);
        ratioComputed = computeSpotRatios(values);
        console.log('ratioComputed', ratioComputed);
      }
    }
  }






  function calculateMean(obj: Record<string, number>): number {
    const values = Object.values(obj);

    if (values.length === 0) {
      throw new Error("Object contains no values to calculate mean.");
    }

    const sum = values.reduce((acc, val) => acc + val, 0);
    return sum / values.length;
  }

  // let meanValue: number = calculateMean(values);
  // console.log(values);
  // console.log('currentModel.chemicalRules?.rules', currentModel.chemicalRules?.rules);
  for (const rule of currentModel.chemicalRules?.rules || []) {
    // confirm string is of proper format
    console.log('rawSignatureMean from rule', rawSignatureMean);
    console.log('tested rule', rule, 'evaluates to', eval(rule.condition));
    if (eval(rule.condition)) {
      // Use caution with eval
      return [rule.result, point, distance];
    }
  }
  return [currentModel.chemicalRules?.default || 'unknown', point, distance];
};

export const classifySignatureWithModel = (currentModel: ModelType | GenericModel, questionningSignature: number[], questionningSpotsgrid1d: number[]): [string, [number, number], number] => {
  console.log(
    'questionningSignature is',
    questionningSignature.map((x) => x.toFixed(2)),
    'with intensity',
    mean(questionningSignature).toFixed(2)
  );
  let distance = 0 // fix this !
  // handle default model with ellipses
  if (instanceOfModelType(currentModel)) {
    let [label, point] = classifySignature(currentModel.groupedScaledEllipses, currentModel.pcaEigenvectors, questionningSignature);

    return [label, point, distance];
  } else {
    // handle generic models
    switch (currentModel.metadata.type) {
      case ModelCategory.ComparisonSignatures:
        if (currentModel.referencesSignatures === undefined) {
          console.log('no references signatures detected, aborting prediction');
          break;
        }
        let distance = 0 // fix this !
        return [classifySignatureComparison(currentModel.referencesSignatures, questionningSignature, currentModel.comparisonThreshold), [0, 0], distance];

      case ModelCategory.ComparisonIntensities:
        if (currentModel.referenceIntensities === undefined) {
          console.log('no references intensities detected, aborting prediction');
          break;
        }

        return [classifyIntensityComparison(currentModel.referenceIntensities, questionningSignature, currentModel.comparisonThreshold), [0, 0], 0];

      case ModelCategory.ChemicalPrediction:
        return classifySignatureRuleBased(currentModel, questionningSignature, questionningSpotsgrid1d);
      case ModelCategory.RulesBased:
        return classifySignatureRuleBased(currentModel, questionningSignature, questionningSpotsgrid1d);
    }
  }
  return ['', [0, 0], 0];
};
