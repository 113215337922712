import { Col, Row } from 'antd';
import SectionPage from '../../../components/widgets/section/SectionPage';
import { useEffect, useState } from 'react';
import { ModelType } from '../../../utils/helpers/byteio/model';
import { QuestionningResult } from '../../../types/types';
import { loadModel } from '../../../services/cache/localStorage';
import { classifySignature } from '../../../components/analysis/classifier';
import { Paper } from '../../../components/common/common';

type OdorDisplayProps = {
  result: QuestionningResult | null;
};

const OdorDisplay: React.FC<OdorDisplayProps> = ({ result }) => {
  return (
    <Paper>
      <Col span={24}>
        <Row justify="center" align="middle" style={{ height: '50vh' }}>
          <img src={`/${result?.label.toUpperCase()}.svg`} alt={result?.label} style={{ width: 'auto', height: '100%', maxHeight: '100%', maxWidth: '80%' }} />
        </Row>
        <Row justify="center">
          <Col span={20}>
            <h1 style={{ textAlign: 'center', fontSize: 30, lineHeight: '30px', marginTop: 20 }}>{result?.label}</h1>
          </Col>
        </Row>
      </Col>
    </Paper>
  );
};

export default OdorDisplay;
