export const OBJECTIVE_COMPARISON_SIMILAR = 'SIMILAR';
export const OBJECTIVE_COMPARISON_DIFFERENT = 'DIFFERENT';
export const INTERPRETED_COMPARISON_PASS = 'PASS';
export const INTERPRETED_COMPARISON_FAIL = 'FAIL';

/*
N - number of records
M - number of spots
*/

export type signal = number[][]; // NxM matrix
export type signature = number[]; // 1xM matrix

/*
Full ellipse definition. To be used in the App
*/
export interface ellipse {
  group: string;
  cx: number; // Center, x
  cy: number; // Center, y
  a: number; // Semi-major axis
  b: number; // Semi-minor axis
  theta: number; // Counter-clockwise angle of rotation in radians (between the x axis and the semi-major axis)
}

/*
Simplified version of ellipse. Already calculated, translated and rotated focal points (x,y)
To be transmitted to the watch
*/
export interface simplifiedEllipse {
  group: string;
  p: number; // Perimeter: 2*a
  focusAx: number; // Coordinates of the right focus of an ellipse, x
  focusAy: number; // Coordinates of the right focus of an ellipse, y
  focusBx: number; // Coordinates of the left focus of an ellipse, x
  focusBy: number; // Coordinates of the left focus of an ellipse, y
}

// Define interfaces for the YAML structure
export interface Rule {
  condition: string;
  result: string;
}

export interface Threshold {
  rawMziDetectionThreshold: number;
  distanceToWaterCalibrantThreshold: number;
  humidityCorrectedMziExploitableThreshold: number;
}

export interface WaterCalibrant {
  id: string;
  timestamp: number;
  waterAffinities: number[];
  spotgrid: number[];
  rawSignature: number[];
}

export interface ClassificationRules {
  rules: Rule[];
  thresholds?: Threshold;
  waterCalibrant?: WaterCalibrant;
  default: string;
}

// Define the types for barycenters
export interface Barycenters {
  [key: string]: [number, number];
}

// define enum
export enum ModelCategory {
  Barycenters = 'Barycenters',
  ComparisonSignatures = 'ComparisonSignatures',
  ComparisonIntensities = 'ComparisonIntensities',
  ChemicalPrediction = 'ChemicalPrediction',
  RulesBased = 'RulesBased',
}

// Define the types for metadata
export interface Metadata {
  created_at: string;
  spotfile: string[];
  ID: string;
  type?: ModelCategory;
  debug?: boolean;
}

// define the types for custom min max normalisation
export interface normalisationRules {
  minSpot: number;
  maxSpot: number;
}

// For Generic Models

// Define the types for metadata
export interface Metadata {
  created_at: string;
  spotfile: string[];
  ID: string;
  type?: ModelCategory;
  debug?: boolean;
}

// Define the main data structure
export interface GenericModel {
  metadata: Metadata;
  projection_axis?: number[][];
  barycenters?: Barycenters;
  referencesSignatures?: number[][];
  referenceIntensities?: number[];
  comparisonThreshold?: number;
  analyteDuration?: number;
  chemicalRules?: ClassificationRules;
  normalisationForChemicalClassification?: normalisationRules;
  barycentersAsSignatures?: Record<string, number[]>[];
  isRoseDemo?: boolean;
  classToStimulationDictionnary?: ClassToStimulationDictionary;
  normalisationType?: string;
}

export interface ClassToStimulationDictionary {
  amine: Stimulation;
  humid_air: Stimulation;
  ketone: Stimulation;
  alcohol: Stimulation;
  acid: Stimulation;
  terpene: Stimulation;
  phenolic: Stimulation;
}

export interface Stimulation {
  frequency: number;
  duration: number;
}
