import { Typography, Row, Col, Button, Collapse, message as antdMessage, Tooltip } from 'antd';
import { FC, useState, useEffect } from 'react';
import { Paper, FlexCol } from '../../common/common';
import { useMessageContext } from '../../../state/context/MessageContext';
import { useMetadataContext } from '../../../state/context/MetadataContext';
import { refkitProtocolGetConfig, refkitProtocolCalibrateExposure, refKitProtocolCalibratePeakMask } from '../../serial/refkit';
import { SingleShotImageWidget } from '../singleShotImageWidget';
import { BugOutlined } from '@ant-design/icons';

export const RefkitCalibrationWidget: FC = () => {
  const { refkitPort, refkitMessages, consumeRefkitMessage } = useMessageContext();
  const { setRefkitConfig } = useMetadataContext();
  const [aecIsLoading, setAecIsLoading] = useState(false);
  const [apdaIsLoading, setApdaIsLoading] = useState(false);

  useEffect(() => {
    for (let message of refkitMessages) {
      if (message.message.result && (message.message.result.calibExpo || message.message.result.calibPeakMask)) {
        console.log('calibration widget calib message', message);
        consumeRefkitMessage(message.id);
        setAecIsLoading(false);
        setApdaIsLoading(false);
        let calib = message.message.result.calibPeakMask || message.message.result.calibExpo;
        if (calib) {
          if (!calib.success) {
            antdMessage.error({
              content: `AEC error: ${calib.reason}`,
            });
          } else {
            antdMessage.success({
              content: `AEC success: ${calib.reason}`,
            });
          }
          if (refkitPort) {
            refkitProtocolGetConfig(refkitPort).then(() => {
              console.log('calibration widget: sent getConfig');
            });
          }
        }
      }
    }
  }, [refkitMessages]);

  useEffect(() => {
    for (let message of refkitMessages) {
      if (message.message.result && message.message.result.config) {
        console.log('calibration widget config message', message);
        consumeRefkitMessage(message.id);
        if (message.message.result.header && !message.message.result.header.status) {
          antdMessage.error({
            content: `${message.message.result.header.reason}`,
          });
          return;
        }
        if (message.message.result.config && message.message.result.config.config) {
          setRefkitConfig(message.message.result.config.config);
        } else {
          console.log('calibration widget config message: no config');
        }
      }
    }
  }, [refkitMessages]);

  // console.log('rendering calibration widget')

  return (
    <Paper>
      <FlexCol>
        <Typography.Title level={3}>
          Optics management <BugOutlined />
        </Typography.Title>
        <Row gutter={[5, 5]}>
          <Col xs={24} lg={12}>
            <Tooltip overlayInnerStyle={{ textAlign: 'center' }} overlay="Exposure calibration (eAEC) is performed on each device connection. No need to do it maually.">
              <Button
                disabled
                block
                type="primary"
                loading={aecIsLoading}
                onClick={async () => {
                  if (!refkitPort) {
                    return;
                  }
                  setAecIsLoading(true);
                  void (await refkitProtocolCalibrateExposure(refkitPort));
                }}
              >
                Calibrate Exposure (eAEC)
              </Button>
            </Tooltip>
          </Col>
          <Col xs={24} lg={12}>
            <Tooltip overlayInnerStyle={{ textAlign: 'center' }} overlay="Peak mask calibration (eAPDA) is performed on each device connection. No need to do it maually.">
              <Button
                disabled
                block
                type="primary"
                loading={apdaIsLoading}
                onClick={async () => {
                  if (!refkitPort) {
                    return;
                  }
                  setApdaIsLoading(true);
                  void (await refKitProtocolCalibratePeakMask(refkitPort));
                }}
              >
                Calibrate Peak Masks (eAPDA)
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Collapse
          items={[
            {
              label: 'Raw Image',
              key: '1',
              children: (
                <Row justify="center">
                  <Col xs={24} lg={16}>
                    <SingleShotImageWidget />
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </FlexCol>
    </Paper>
  );
};
