import { useEffect, useRef, useState } from 'react';
import BaselineRecording from './OdorIdentifiacationSteps/BaselineRecording';
import AnalyteRecording from './OdorIdentifiacationSteps/AnalyteRecording';
import OdorAnalysis from './OdorIdentifiacationSteps/OdorAnalysis';
import OdorDisplay from './OdorIdentifiacationSteps/OdorDisplay';
import GenericDisplay from './OdorIdentifiacationSteps/GenericDisplay';
import SensorCleaning from './OdorIdentifiacationSteps/SensorCleaning';
import { DeviceValue, RecordKey, commitSensogramPartition } from '../../services/cache/idb';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE } from '../../components/serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { loadModel, loadGenericModel, loadSpotsGrid1D, loadHumidityCalibrant, saveCurrentDeltaHumidityValue, saveRawSignatureSize64 } from '../../services/cache/localStorage';

import {
  DEFAULT_ANALYSIS_COMPARISON_THRESHOLD,
  DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC,
  DEFAULT_PLOT_DECIMATED_FPS,
  DEFAULT_RAW_FPS,
  DEFAULT_STORAGE_DECIMATED_FPS,
  IDB_PARTITION_WINDOW_SIZE,
  PLOT_WINDOW_SIZE,
} from '../../utils/constants/constants';
import { mean, standardDeviation, transpose } from '../../components/analysis/utils';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../../components/serial/constants';
import { DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW } from '../../utils/helpers/utils';
import { aggregateSignature, normalizeL2, sortSignature, parseBiosensorsSignalMessagePayload, updateKineticSeries, shouldCompute, updateQuestioningState, minmaxNormaliseWithGenericModelSpotDefinition } from '../../components/analysis/compute';
import { processMziData } from '../../components/analysis/mzi';
import { GenericModel, ModelCategory } from '../../components/analysis/definitions';
import { QuestionningResult, SignatureWithSpotgrid } from '../../types/types';
import { classifySignature, classifySignatureRuleBased, classifySignatureWithModel } from '../../components/analysis/classifier';
import Plot from 'react-plotly.js';
import { DEFAULT_PLOTLY_CONFIG, DEFAULT_PLOTLY_LAYOUT } from '../../utils/constants/constants';
import { getSignatureFigure, getFigureFromGenericModel } from '../../components/analysis/figures';
import { signature } from '../../components/analysis/definitions';
import { pcaTransform } from '../../components/analysis/pca';
import type { InputNumberProps } from 'antd';
import { Button, Card, Col, InputNumber, Row, Slider, Space, Switch } from 'antd';
import { Paper } from '../../components/common/common';
import { ReloadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { IntensityGauge } from '../../components/widgets/Gauges/IntensityGauge';
import { useMessageContext } from '../../state/context/MessageContext';
import { SecondData } from '../ChemicalFamilyIdentificationPage/CsmChemicalFamilyIdentificationPage';
import BubbleChart from '../../components/widgets/ChemicalFamilyIdentification/BubbleChart';
import { Typography } from 'antd';
import DebugInfo from '../../components/widgets/Parameters/DebugInfos';
import GlobalParameters from '../../components/widgets/Parameters/GlobalParameters';
import ResetToZero from '../../components/widgets/Parameters/ResetToZero';
import GlobalParametersComponent from './OdorIdentificationComponents/GlobalParametersComponent';
import GlobalParametersIdentificationComponent from './OdorIdentificationComponents/GlobalParametersComponent';
import Ready from './OdorIdentifiacationSteps/Ready';

type CsmOdorIdentificationProps = {};
enum QuestioningState {
  SensorCleaning = 'SensorCleaning',
  BaselineRecording = 'BaselineRecording',
  Ready = 'Ready',
  AnalyteRecording = 'AnalyteRecording',
  GenericDisplay = 'GenericDisplay',
  OdorAnalysis = 'OdorAnalysis',
  OdorDisplay = 'OdorDisplay',
}

enum SenseMode {
  Recording,
  Questionning,
}

export const CsmOdorIdentification: React.FC<CsmOdorIdentificationProps> = () => {
  const [rawQuestionningSignature, setRawQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  const { csmMessages, csmFwVersion, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  // * humidity compensation
  const [humidityCalibrant, setHumidityCalibrant] = useState<SignatureWithSpotgrid | undefined>(undefined);
  const humidityBaselineRef = useRef<number | null>(null);
  const [humidityCompensationEnabled, sethumidityCompensationEnabled] = useState<boolean>(false);
  // *

  // * MZI and timestamps related useRefs
  const firstMZIsRef = useRef<number[] | null>(null);
  const signalEnvelopeAvgRef = useRef<number>(0);
  const signalMZI = useRef<number[]>([]);


  const rawMZISeriesRef = useRef<number[][]>([]);
  const averageMZISeriesRef = useRef<number[]>([]);
  const isMZIcomputingrequested = useRef<boolean>(false);

  // * HIH useRefs
  const humidityDisplayed = useRef<number>(0);
  const temperatureDisplayed = useRef<number>(0);
  // *

  // * ALGO
  //
  const decimatedMZISeriesCorrected = useRef<number[][]>([]);
  const questioningState = useRef<QuestioningState>(QuestioningState.BaselineRecording);

  const noizeLevelRef = useRef<number>(0);
  const isOdorPresentRef = useRef<boolean>(false);
  const odorPresenceThresholdLevelRef = useRef<number>(0);
  const maxOdorPresentValue = useRef<number>(0);
  const odorPresentStartTimestampRef = useRef<number>(0);
  const odorPresentStopTimestampRef = useRef<number>(0);
  const odorPresentLastRecognitionTimestampRef = useRef<number>(0);
  const signalEnvelopeMinRef = useRef<number>(0);
  const signalEnvelopeMaxRef = useRef<number>(0);

  const decimatedMZISeriesRef = useRef<number[][]>([]);
  const analyteMZISeriesRef = useRef<number[][]>([]);

  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const rawTimestampSeriesRef = useRef<number[]>([]);

  const decimatedMZIPartitionSeriesRef = useRef<number[][]>([]);
  const decimatedTimestampPartitionSeriesRef = useRef<number[]>([]);

  const decimatedFpsTimeseriesRef = useRef<number[]>([]);
  const rawFpsTimeseriesRef = useRef<number[]>([]);


  const lastDecimationTickRef = useRef<number>(0);

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);




  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);

  const [showGlobalParameters, setShowGlobalParameters] = useState<boolean>(false);
  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));

  const [currentModel, setCurrentModel] = useState<GenericModel | null>(null);
  // const [currentModel, setCurrentModel] = useState<GenericModel | null>(null); back to image display for September demo
  const [questionningResult, setQuestionningResult] = useState<QuestionningResult | null>(null);


  const [signatureWindowValue, setSignatureWindowValue] = useState(DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
  const [thresholdOverride, setThresholdOverride] = useState(DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);

  // chemical bubble display
  const [currentProba, setCurrentProba] = useState<SecondData[]>([]);

  const [active, setActive] = useState(true);

  // reset MZI for display centered around 0
  const onClickReset = () => {
    questioningState.current = QuestioningState.BaselineRecording;
    setQuestionningSignature([]);

    firstMZIsRef.current = null;

    noizeLevelRef.current = 0;
    isOdorPresentRef.current = false;
    odorPresenceThresholdLevelRef.current = 0;
    maxOdorPresentValue.current = 0;
    odorPresentStartTimestampRef.current = 0;
    odorPresentStopTimestampRef.current = 0;
    odorPresentLastRecognitionTimestampRef.current = 0;
    signalEnvelopeMinRef.current = 0;
    signalEnvelopeMaxRef.current = 0;
    signalEnvelopeAvgRef.current = 0;
    decimatedMZISeriesCorrected.current = [];

    decimatedMZISeriesRef.current = [];
    rawMZISeriesRef.current = [];
    analyteMZISeriesRef.current = [];

    decimatedTimestampSeriesRef.current = [];
    rawTimestampSeriesRef.current = [];
    decimatedMZIPartitionSeriesRef.current = [];
    decimatedTimestampPartitionSeriesRef.current = [];
    decimatedFpsTimeseriesRef.current = [];
    rawFpsTimeseriesRef.current = [];

    setMachineState(States.READY);
    setQuestionningResult(null);
    setAnalyteHihDetected(false);
    setBufferHihAnalyte([])
    setBufferHih([])
    setActive(true)
  };

  const onTrig = () => {
    questioningState.current = QuestioningState.AnalyteRecording;
    console.log('clicked!');
    setActive(!active)
  };



  // *** HIH triggering testing

  // Define machine states
  enum States {
    INIT = "INIT",
    SAMPLING = "SAMPLING",
    READY = "READY",
    CLEANING = "CLEANING",
  }





  const [machineState, setMachineState] = useState<States>(States.INIT);
  const [bufferHih, setBufferHih] = useState<number[]>([]);
  const [bufferHihAnalyte, setBufferHihAnalyte] = useState<number[]>([]);
  const [hihDifference, setHihDifference] = useState<number>(0);
  const [sensorReadiness, setSensorReadiness] = useState(false);
  const [baselineHihDetected, setBaselineHihDetected] = useState(false);

  const [analyteHihDetected, setAnalyteHihDetected] = useState(false);

  const currentStdHihAnalyteDisplay = useRef<number>(0);
  const distanceDisplay = useRef<number>(0);



  // const STD_HIH_THRESHOLD: number = 0.5;
  // const STD_HIH_STABLE: number = 2;
  // const hihBufferLength: number = 10;
  // const analyteHihBufferLength: number = 20;

  const [stdHihThreshold, setStdHihThreshold] = useState<number>(0.5);
  const [stdHihStable, setStdHihStable] = useState<number>(2);
  const [hihBufferLength, setHihBufferLength] = useState<number>(10);
  const [analyteHihBufferLength, setAnalyteHihBufferLength] = useState<number>(20);

  const getMean = (arr: number[]) => arr.length > 0 ? arr.reduce((a, b) => a + b, 0) / arr.length : 0;
  const getStd = (arr: number[]) => {
    if (arr.length < 2) return 0;
    const mean = getMean(arr);
    return Math.sqrt(arr.reduce((sum, value) => sum + (value - mean) ** 2, 0) / arr.length);
  };

  const updateHIHbasedMachineState = (humidity: number) => {

    const lastMeanHih = getMean(bufferHih);
    const currentMeanHih = getMean(bufferHih);
    const currentStdHih = getStd(bufferHih);
    const currentStdHihAnalyte = getStd(bufferHihAnalyte);
    currentStdHihAnalyteDisplay.current = currentStdHihAnalyte
    const currentMeanAnalyteHih = getMean(bufferHihAnalyte);
    // console.log('currentState', machineState, 'std value', currentStdHih.toFixed(2), 'analyteHihDetected', analyteHihDetected, questioningState.current, currentStdHihAnalyte.toFixed(2));
    // console.log('isReady ?', machineState);
    // console.log('algo state', questioningState.current);
    switch (machineState) {

      case States.INIT:
        if (sensorReadiness) {
          console.log("Changing to ready mode. Introduce sample...");
          setMachineState(States.READY);
        }
        else { setSensorReadiness(true) }
        break;

      case States.READY:

        if (questioningState.current === QuestioningState.AnalyteRecording) {
          bufferHih.push(humidity)
          bufferHih.shift();
          setBufferHih(bufferHih);

          console.log("Changing to sampling mode");
          setMachineState(States.SAMPLING);

        }
        else {
          // accumulate or shift bufferHih buffer

          if (bufferHih.length > hihBufferLength) {
            bufferHih.push(humidity)
            bufferHih.shift();
            console.log('shifting baseline HIH buffer');
          }
          else if (bufferHih.length === hihBufferLength) {
            console.log(`Baseline HIH detected: ${lastMeanHih}`, questioningState.current);
            questioningState.current = QuestioningState.Ready;
            setBaselineHihDetected(true);
            bufferHih.push(humidity)

          }
          else {
            bufferHih.push(humidity);
          }

          setBufferHih(bufferHih);
        }

        break;

      case States.SAMPLING:

        if (questioningState.current !== QuestioningState.AnalyteRecording) return;
        if (bufferHihAnalyte.length >= analyteHihBufferLength) {
          bufferHihAnalyte.push(humidity)
          bufferHihAnalyte.shift();
          console.log(`sampling...shifting AnalyteHIH`, currentStdHihAnalyte, stdHihStable, bufferHihAnalyte, hihBufferLength, analyteHihDetected);


          if (currentStdHih > stdHihThreshold && analyteHihDetected) {
            console.log("Changing to cleaning mode");
            setMachineState(States.CLEANING);
          }

          else if (!analyteHihDetected && bufferHihAnalyte.length >= analyteHihBufferLength && currentStdHihAnalyte < stdHihStable) {
            setAnalyteHihDetected(true);
            console.log('stable hih buffer detected');
            // console.log(`Analyte HIH detected: ${currentMeanHih}`);
            questioningState.current = QuestioningState.OdorAnalysis;
            saveCurrentDeltaHumidityValue(currentMeanAnalyteHih - currentMeanHih)
            setHihDifference(currentMeanAnalyteHih - currentMeanHih);
            console.log('algo state with stable HIH', questioningState.current);
            setBufferHihAnalyte([])
            // console.log('questioningState.current', questioningState.current);
          }
        }

        else {
          console.log(`sampling...building analyteHIH`, currentStdHihAnalyte);

          bufferHihAnalyte.push(humidity);
        }
        break;


      case States.CLEANING:
        if (currentStdHih < stdHihStable) {
          console.log("Changing to ready mode. Introduce sample...");
          setMachineState(States.READY);
          setBufferHih([]);
          setBufferHihAnalyte([]);
          setAnalyteHihDetected(false);
          setBaselineHihDetected(false);
        }
        break;
    }
  }
  // useEffect(() => {

  // }, [humidityDisplayed.current, machineState, bufferHih, sensorReadiness, analyteHihDetected]);


  // *  HIH triggering testing ends* //

  // load humidity calibrant
  useEffect(() => {
    const hCalibrant = loadHumidityCalibrant();
    setHumidityCalibrant(hCalibrant);
    console.log('humidityCalibrant is', hCalibrant);
  }, []);

  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
    // Aggregate MZIs by peptide
    let _aggregationIndicesMap: Record<number, number[]> = {};
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let aggKey = _spotsgrid1d[i];
      if (aggKey < 0) {
        continue;
      }
      if (_aggregationIndicesMap[aggKey] === undefined) {
        _aggregationIndicesMap[aggKey] = [];
      }
      _aggregationIndicesMap[aggKey].push(i);
    }
    setAggregatedIndicesMap(_aggregationIndicesMap);
    // console.log("sense page: _aggregationIndicesMap", _aggregationIndicesMap)
  }, []);

  useEffect(() => {
    if (csmMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }
    // console.log("sense page: acquiring mutex..")

    // implement me : unfoldBiosensorEvent

    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        let nFramesProcessed = 0;

        csmMessages.forEach((message) => {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('sense page: csm ble message is not a biosensors signal event', message.message);
            consumeCSMMessage(message.id);
            return;
          } else {
            nFramesProcessed++;
            consumeCSMMessage(message.id);

            // Parse message payload
            let mzis = parseBiosensorsSignalMessagePayload(message, csmFwVersion);

            if (!mzis) return;

            // set Baseline delta H for future use
            if (humidityBaselineRef.current === null) {
              console.log('Changing humidity baseline reference as ', hihValues.humidity);
              humidityBaselineRef.current = hihValues.humidity;
            }

            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...mzis];
            }
            rawMZISeriesRef.current.push(mzis);

            isMZIcomputingrequested.current = shouldCompute(message.ts, lastDecimationTickRef); // shouldCompute is in fact decimation
            // console.log('humiditycalibrant', humidityCalibrant);
            // console.log('humidityCompensationEnabled.current', humidityCompensationEnabled);

            if (isMZIcomputingrequested.current) {


              let [averageMZI, decimatedMZI, correctedMZI] = processMziData(rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef.current, hihValues) || [0, []];

              if (correctedMZI) {
                updateKineticSeries(averageMZI, correctedMZI, averageMZISeriesRef, decimatedMZISeriesCorrected);
              }

              // console.log('decimatedMZISeriesCorrected.current are', decimatedMZISeriesCorrected.current);
              if (isMZIcomputingrequested.current) {
                // to prevent frequent rendereing
                // console.log('averageMZI...', averageMZI, ' humidity correction is', humidityCompensationEnabled, 'lastDecimationTickRef.current is', lastDecimationTickRef.current)

                // Update displayed values
                signalEnvelopeAvgRef.current = averageMZI; // for display in the component
                signalMZI.current = decimatedMZISeriesCorrected.current[decimatedMZISeriesCorrected.current.length - 1];
                // console.log('signalMZI.current', decimatedMZISeriesCorrected.current[decimatedMZISeriesCorrected.current.length - 1]);
                humidityDisplayed.current = hihValues.humidity;
                temperatureDisplayed.current = hihValues.temperature;

                // update states based on HIH
                updateHIHbasedMachineState(hihValues.humidity)

                // update algo state
                updateQuestioningState(questioningState, averageMZI, averageMZISeriesRef.current, decimatedMZISeriesCorrected.current, isOdorPresentRef, maxOdorPresentValue, odorPresenceThresholdLevelRef, noizeLevelRef);
                // console.log('mutex algo state is', questioningState.current);

              }
            }

          }
        });
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        rawMZISeriesRef.current = []; // flushing
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  useEffect(() => {
    // if (questioningState !== QuestioningState.OdorAnalysis) return;
    let _model = loadGenericModel();
    console.log(' Selected Model is :', _model);
    // let _model = loadModel();
    setSignatureWindowValue(_model?.analyteDuration || DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
    setThresholdOverride(_model?.comparisonThreshold || DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);
    setCurrentModel(_model);
  }, []);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorAnalysis) return;
    if (questionningSignature === null) {
      console.log('questioning result widget: null signature');
      setQuestionningResult(null);
      return;
    }
    if (questionningSpotsgrid1d === null) {
      console.log('questioning result widget: null spotsgrid1d');
      return;
    }
    if (currentModel === null) {
      console.log('questioning result widget: received signature upon null model');
      return;
    }

    // classify according to the model type
    // console.log('raw signature is ', questionningSignature);

    let label, point, distance: number

    [label, point, distance] = classifySignatureWithModel(currentModel, questionningSignature, questionningSpotsgrid1d);
    distanceDisplay.current = distance;
    let _questionningResult: QuestionningResult = {
      label: label,
      point: point,
    };
    // console.log('Model Threshold is :', currentModel.comparisonThreshold);
    console.log('Questionning result is :', _questionningResult);
    setQuestionningResult(_questionningResult);
    if (currentModel?.metadata.type === ModelCategory.ChemicalPrediction) {
      // console.log('getting chemical prediction questionning spotgrid is', questionningSpotsgrid1d);
      const prediction = label
      console.log('prediction is', prediction);

      // set in structure to display bubble later
      const packagedData: SecondData[] = [
        {
          id: 'example-id', // You can use any unique ID here
          content: Object.entries(currentModel.barycenters || {}).map(([key, value]) => ({
            peptide: key,
            ratio: key === prediction ? 35 : 7, // hardcode found chemical family : size 3 other wise 1.
          })),
        },
      ];
      console.log('packagedData', packagedData);
      setCurrentProba(packagedData);
    } else {
      console.log('other type of model - not chemical');
    }
    questioningState.current = QuestioningState.OdorDisplay;
  }, [questionningSignature, currentModel]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorDisplay) return;
    setTimeout(() => {
      questioningState.current = QuestioningState.SensorCleaning;
    }, 10000);
  }, [questioningState, questionningSignature]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.SensorCleaning) return;
    if (Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100 <= 0.2) {
      // purge analyteMZISeriesRef
      analyteMZISeriesRef.current = [];
      onClickReset()
      questioningState.current = QuestioningState.BaselineRecording;
    }
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorAnalysis) return;
    // if (Math.abs(Math.round(100 * Number(signalEnvelopeAvgRef.current))) / 100 <= 0.2 && !humidityCompensationEnabled) {
    //   // purge analyteMZISeriesRef
    //   analyteMZISeriesRef.current = [];
    //   // console.log('alarm');
    //   questioningState.current = QuestioningState.BaselineRecording;
    // } else {
    //   // compute signature and return
    //   // console.log('compute and return');

    // }
    constructSignature();
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.AnalyteRecording) return;

    // capture frames once analyte is started during a specified signatureWindowValue amount of time
    if (analyteMZISeriesRef.current.length < DEFAULT_PLOT_DECIMATED_FPS * signatureWindowValue) {
      analyteMZISeriesRef.current.push(signalMZI.current);
      // console.log('analyte buffer is filling...', analyteMZISeriesRef.current.length);
    }
    else {

      isOdorPresentRef.current = false;
      maxOdorPresentValue.current = 0;
      odorPresenceThresholdLevelRef.current = 0;
      // questioningState.current = QuestioningState.OdorAnalysis;
      // sliding the buffer
      analyteMZISeriesRef.current.push(signalMZI.current);
      analyteMZISeriesRef.current.shift();
      // console.log('analyte buffer is shifting...', analyteMZISeriesRef.current.length);

    }
    // buffer is filled and kept at initial value, not sliding value. We could implement a toggle if the user prefer the initial signature or the sliding one
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  const constructSignature = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * signatureWindowValue;
    }
    let sectionMZIs = analyteMZISeriesRef.current;
    let sectionMZIsSpans = transpose(sectionMZIs);
    // console.log('analyteMZISeriesRef.current', analyteMZISeriesRef.current);
    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    saveRawSignatureSize64(_signature)
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      if (sensorInt >= 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    // console.log('finalSpotsgrid1d', finalSpotsgrid1d)
    // console.log('sortedFinalSpotsgrid1d', sortedFinalSpotsgrid1d);
    // disable normallisation when comparing intensities

    if (currentModel?.metadata?.type === ModelCategory.ComparisonIntensities || currentModel?.normalisationType === 'none') {
      // no normalisation
      setQuestionningSignature(sortedFinaleSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);

    }
    else if (currentModel?.metadata.type === ModelCategory.ChemicalPrediction) {
      // min max
      // console.log('min max signature', minmaxNormaliseWithGenericModelSpotDefinition(aggregatedSignature, finalSpotsgrid1d, currentModel));
      setQuestionningSignature(minmaxNormaliseWithGenericModelSpotDefinition(aggregatedSignature, finalSpotsgrid1d, currentModel));
      setQuestionningSpotsgrid1d(finalSpotsgrid1d);

    }

    else {
      // L2 normalisation
      let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);
      setQuestionningSignature(normalizedSortedAggregatedSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
    }


  };

  return (
    <>
      {/* parameters */}
      <Row justify="end">
        <GlobalParameters showGlobalParameters={showGlobalParameters} setShowGlobalParameters={setShowGlobalParameters} />
        <ResetToZero firstMZIsRef={firstMZIsRef} onClickReset={onClickReset} />
      </Row>

      {questioningState.current === QuestioningState.BaselineRecording && (
        <BaselineRecording MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: humidityDisplayed.current }} />
      )}
      {questioningState.current === QuestioningState.Ready && (
        <Ready MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: humidityDisplayed.current }} />
      )}
      {questioningState.current === QuestioningState.AnalyteRecording && (
        <AnalyteRecording MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: humidityDisplayed.current }} />
      )}
      {questioningState.current === QuestioningState.OdorAnalysis && <OdorAnalysis />}
      {questioningState.current === QuestioningState.GenericDisplay && <GenericDisplay result={questionningResult} />}

      {questioningState.current === QuestioningState.OdorDisplay && currentModel?.metadata.type === ModelCategory.ChemicalPrediction && (
        <Paper style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <BubbleChart data={currentProba} currentChemicalFamily={'toto'} />
        </Paper>
      )}
      {questioningState.current === QuestioningState.OdorDisplay && currentModel?.metadata.type !== ModelCategory.ChemicalPrediction && <OdorDisplay result={questionningResult} />}

      {questioningState.current === QuestioningState.SensorCleaning && <SensorCleaning MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />}

      <Button
        type="default" // or "primary" for a solid look
        size="large"
        onClick={() => onTrig()}
        disabled={!active}
        style={{
          border: "2px solid #1890ff", // Customize the border color
          borderRadius: "8px", // Optional: rounded corners
          padding: "8px 16px",
        }}
      >
        {active ? "SMELL" : "Click to cancel"}
      </Button>

      <GlobalParametersIdentificationComponent
        showGlobalParameters={showGlobalParameters}
        signatureWindowValue={signatureWindowValue}
        setSignatureWindowValue={setSignatureWindowValue}
        thresholdOverride={thresholdOverride}
        setThresholdOverride={setThresholdOverride}
        currentModel={currentModel}
        setCurrentModel={setCurrentModel}
        noizeLevelRef={noizeLevelRef}
        humidityCompensationEnabled={humidityCompensationEnabled}
        sethumidityCompensationEnabled={sethumidityCompensationEnabled}
        stdHihThreshold={stdHihThreshold}
        setStdHihThreshold={setStdHihThreshold}
        stdHihStable={stdHihStable}
        setStdHihStable={setStdHihStable}
        hihBufferLength={hihBufferLength}
        setHihBufferLength={setHihBufferLength}
        analyteHihBufferLength={analyteHihBufferLength}
        setAnalyteHihBufferLength={setAnalyteHihBufferLength}
        currentStdHihAnalyteDisplay={currentStdHihAnalyteDisplay}
        distanceDisplay={distanceDisplay}
      />



    </>
  );
};
