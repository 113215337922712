import { crc16ccitt } from 'crc';
import { shouldTrimSpotsgrid, trimSensor } from '../../utils/helpers/utils';
import { MMI_LENGTH } from '../../utils/constants/constants';

export enum PERIPHERAL_ADDRESS {
  CSM = 0x01,
  HIH = 0x02,
  ARDUINO = 0x03,
}

export enum CSM_PROTOCOL_EVENT_TYPE {
  ErrorEvent = 0xee,
  HeartbeatEvent = 0xe0,
  BiosensorsSignalEvent = 0xe1,
  LifeCycleEvent = 0xe2,
  ImageEvent = 0xe3,
  MmiEvent = 0xe4,
}

export enum CSM_PROTOCOL_COMMAND_TYPE {
  DeviceReset = 0x01,
  GetVersions = 0x06,
  GetBiosensorSignalMap = 0x0a,
  StartSampling = 0x20,
  StopSampling = 0x21,
  SetReference = 0x22,
  SetBiosensorSerialNumberAndSignalMap = 0x45,
  GetBiosensorSerialNumber = 0x46,
  SetSamplingRate = 0x50,
  GetSamplingRate = 0x51,

  // In BLE mode the commands below are collected and processed by Arduino directly
  SetPumpPower = 0x30,

  // for tboard
  GetImage = 0x10,
  GetPeakMask = 0x11,
}

export enum CSM_PROTOCOL_MISO_RESPONSE_STATUS {
  UnhandledCommand = 0x00,
  Successful = 0x01,
  WrongDataLength = 0x02,
  NoAccessRight = 0x03,
  IllegalCommandParameter = 0x04,
  NotAbleToExecute = 0x05,
  UnknownError = 0xff,
}

export enum CSM_PROTOCOL_LIFECYCLE_EVENT_STATE {
  Initializing = 0x01,
  Calibrating = 0x02,
  Ready = 0x03,
  Acquiring = 0x04,
  InitializationError = 0xe0,
  CalibrationError = 0xe1,
}

export enum ARDUINO_EVENT_TYPE {
  CutMsg = 0x01,
}

export enum ARDUINO_CUT_MSG_EVENT_STATE {
  Idle = 0x01,
  Sending = 0x02,
}

export const CSM_PROTOCOL_START = 0x55;
export const CSM_PROTOCOL_STOP = 0x0d;

export interface CSMMisoFrame {
  StartFlag: number; // 1 byte
  PeripheralAddress: number; // 1 byte
  Type: number; // 1 byte
  Payload: Uint8Array; // n bytes
  LocalCRC: number; // 2 bytes
  RemoteCRC: number; // 2 bytes
  StopFlag: number; // 1 byte
}

export interface ArduinoMsgCutEvent {
  PeripheralAddress: number; // 1 byte
  Type: number; // 1 byte
  MsgId: number; // 1 byte
  MsgLength: number; // 2 bytes
  NbPackets: number; // 2 bytes
  State: number; // 1 byte
}

export interface CSMCommand {
  CmdType: CSM_PROTOCOL_COMMAND_TYPE;
  Payload?: Uint8Array;
}

export interface CSMEventPayload {
  Counter: number; // 1 byte
  Tick: number; // 4 bytes
  Data: Uint8Array; // n bytes
}

export interface CSMResponsePayload {
  Status: CSM_PROTOCOL_MISO_RESPONSE_STATUS;
  Data: Uint8Array;
}

export type VersionsInfoMap = Record<string, string>;
export enum VERSION_INFO_ENTRIES {
  fwVersion = 'fwVersion',
  hwVersion = 'hwVersion',
}

export type PeakPositionsType = {
  x: number[];
  y: number[];
};

const destuffBytes = (src: Uint8Array): Uint8Array => {
  let dst = new Uint8Array(src.byteLength);
  var pos = 0;
  for (let i = 0; i < src.length; i++) {
    var b, bNext;
    b = src[i];
    if (i < src.length - 1) bNext = src[i + 1];
    if (b == 0x7f) {
      if (bNext == 0xa5) {
        b = 0x55;
        i++;
      } else if (bNext == 0xad) {
        b = 0x0d;
        i++;
      } else if (bNext == 0xaf) {
        b = 0x7f;
        i++;
      }
    }
    dst[pos] = b;
    pos++;
  }
  if (pos < dst.length) {
    // console.log("destuffBytes: pos < dst.length: ", pos, dst.length)
    dst = dst.slice(0, pos);
  }
  return dst.slice(0, pos);
};

const stuffBytes = (src: Uint8Array): Uint8Array => {
  let dst = new Uint8Array(src.byteLength * 2);
  var pos = 0;
  for (let i = 0; i < src.length; i++) {
    var b = src[i];
    if (b == 0x55) {
      dst[pos] = 0x7f;
      dst[pos + 1] = 0xa5;
      pos += 2;
    } else if (b == 0x0d) {
      dst[pos] = 0x7f;
      dst[pos + 1] = 0xad;
      pos += 2;
    } else if (b == 0x7f) {
      dst[pos] = 0x7f;
      dst[pos + 1] = 0xaf;
      pos += 2;
    } else {
      dst[pos] = b;
      pos++;
    }
  }
  return dst.slice(0, pos);
};

export const parseMisoFrame = (frame: Uint8Array): CSMMisoFrame => {
  let frameView = new DataView(frame.buffer);
  if (frameView.byteLength < 2) {
    console.log('Invalid MISO frame', frameView);
  }
  let peripheralAddress = frameView.getUint8(0);
  let type = frameView.getUint8(1);
  let payload = new Uint8Array(frameView.buffer.slice(2, frameView.byteLength - 2));
  let remoteCrc = frameView.getUint16(frameView.byteLength - 2, true);

  let localCrcSrcBytes = frame.slice(0, frame.byteLength - 2);
  let localCrc = crc16ccitt(localCrcSrcBytes);
  localCrc = ((localCrc & 0xff) << 8) | ((localCrc >> 8) & 0xff);

  if (remoteCrc !== localCrc) {
    console.debug('parseMisoFrame: CRC mismatch: ', remoteCrc, localCrc);
  }

  return {
    StartFlag: 0,
    PeripheralAddress: peripheralAddress,
    Type: type,
    Payload: payload,
    LocalCRC: localCrc,
    RemoteCRC: remoteCrc,
    StopFlag: 0,
  };
};

export const parseEventPayload = (payload: Uint8Array): CSMEventPayload => {
  let view = new DataView(payload.buffer);
  let counter = view.getUint8(0);
  let tick = view.getUint32(1, true);
  let data = new Uint8Array(payload.buffer.slice(5, payload.byteLength));

  return {
    Counter: counter,
    Tick: tick,
    Data: data,
  };
};

export const parseResponsePayload = (payload: Uint8Array): CSMResponsePayload => {
  let status = payload[0];
  if (status !== 1) {
    let reason = new TextDecoder().decode(payload.slice(1, payload.byteLength));
    throw Error(`parseResponsePaylaod: status !== 1: ${status}; reason: ${reason}`);
  }
  return {
    Status: status,
    Data: payload.slice(1, payload.byteLength),
  };
};

/**
 * Parses MZI signal event from the given data buffer.
 *
 * This function processes a Uint8Array containing signal event data and optionally utilizes the firmware version
 * to apply the right method to decode the MZI bytes. Default behaviour is to suppose MZIs are float32s encoded on 4 bytes.
 *
 * @param {Uint8Array} data - A buffer containing signal event data that needs to be parsed.
 * @param {string} [fwVersion=''] - The firmware version, used to choose how to decode MZI values. Defaults to an empty string.
 *
 * @returns {number[]} - The parsed MZI returned as an array of numbers.
 *
 * @throws {Error} If the data is invalid or cannot be processed.
 *
 */
export const parseBiosensorsSignalEvent = (data: Uint8Array, fwVersion: string = '') => {
  // if firmware is encoding MZIs on 2-byte
  let nbBytesMZI = fwVersion.startsWith('TB') ? 2 : 4;

  if (data.byteLength % nbBytesMZI !== 0) {
    throw Error(`parseBiosensorsSignalEvent: data.byteLength % ${nbBytesMZI} !== 0`);
  }
  let view = new DataView(data.buffer);
  let float32s = [];
  for (let i = 0; i < data.byteLength; i += nbBytesMZI) {
    if (nbBytesMZI === 2) {
      float32s.push(view.getInt16(i, true) / 100);
    } else {
      float32s.push(view.getFloat32(i, true));
    }
  }

  return float32s;
};

export const parseBiosensorSignalMapResponse = (data: Uint8Array) => {
  if (data.byteLength % 2 !== 0) {
    throw Error('parseBiosensorSignalMap: data.byteLength % 2 !== 0');
  }
  let view = new DataView(data.buffer);
  let spotsgrid1d: number[] = [];
  for (let i = 0; i < data.byteLength; i += 2) {
    let spotId = view.getInt16(i, true);
    spotsgrid1d.push(spotId);
  }
  console.log('spotgrid 1D is: ', spotsgrid1d);
  // drop 4 only if peptides are not trimmed and if all appear in raw peptides list
  let spotgrid1D_renamed = spotsgrid1d;
  if (shouldTrimSpotsgrid(spotgrid1D_renamed)) {
    spotgrid1D_renamed = spotgrid1D_renamed.map((elt) => trimSensor(elt));
  }
  console.log('spotgrid 1D renamed  is: ', spotgrid1D_renamed);
  //const test = spotgrid1D_renamed.filter((s) => s !== 1);
  //console.log('spotgrid filter', test);
  return spotgrid1D_renamed;
};

export const encodeBiosensorSignalMap = (spotsgrid1d: number[]): Uint8Array => {
  let data = new Uint8Array(spotsgrid1d.length * 2);
  let view = new DataView(data.buffer);
  for (let i = 0; i < spotsgrid1d.length; i++) {
    view.setInt16(i * 2, spotsgrid1d[i], true);
  }
  return data;
};

export const parseVersionsResponse = (data: Uint8Array): VersionsInfoMap => {
  let str = new TextDecoder().decode(data);
  return JSON.parse(str);
};

export const parsePeakPositionResponse = (data: Uint8Array): PeakPositionsType => {
  let result: PeakPositionsType = {
    x: [],
    y: [],
  };

  // compare byteLength-1 because the first byte corresponds to a status, not to a peak position information
  if (data.byteLength - 1 !== 2 * 2 * MMI_LENGTH) {
    throw Error(`parsePeakPositionResponse: number of peaks is not ${MMI_LENGTH} ! Received peak array of size: ${data.byteLength - 1} instead of ${MMI_LENGTH}*2*2`);
  }
  let view = new DataView(data.buffer);
  for (let i = 1; i < data.byteLength; i += 2) {
    let peakPosition = view.getInt16(i, true);

    if (i < MMI_LENGTH * 2) {
      result.x.push(peakPosition);
    } else {
      result.y.push(peakPosition);
    }
  }

  return result;
};

export const decodeMisoFrame = (uint8array: Uint8Array): CSMMisoFrame => {
  let destuffedArray = destuffBytes(uint8array);
  return parseMisoFrame(destuffedArray);
};

export const encodeMosiCommand = (cmdType: CSM_PROTOCOL_COMMAND_TYPE, payload?: Uint8Array): Uint8Array => {
  console.log(cmdType);
  if (!payload || payload.byteLength === 0) {
    payload = new Uint8Array(1);
  }
  let payloadStuffed = stuffBytes(payload);
  let frame = new Uint8Array(3 + payloadStuffed.byteLength + 3);
  frame[0] = CSM_PROTOCOL_START;
  frame[1] = 0x01; // peripheral address
  frame[2] = cmdType;
  frame.set(payloadStuffed, 3);
  let localCrc = crc16ccitt(frame.slice(1, frame.byteLength - 3));
  let crcBytesView = new DataView(new ArrayBuffer(2));
  crcBytesView.setUint16(0, localCrc);
  frame.set(new Uint8Array(crcBytesView.buffer), frame.byteLength - 3);
  frame[frame.byteLength - 1] = CSM_PROTOCOL_STOP;
  return frame;
};

export const encodeMosiSetBiosensorCommandData = (biosensorSerialNumber: string, spotsgrid1d: number[]): Uint8Array => {
  const stringBytes = new Uint8Array(32);
  for (let i = 0; i < biosensorSerialNumber.length && i < 32; i++) {
    stringBytes[i] = biosensorSerialNumber.charCodeAt(i) & 0xff;
  }
  // Create a new Uint8Array with a length of 64*2 + 32 = 160
  const combinedArray = new Uint8Array(32 + 64 * 2);
  // Copy the string bytes into the first 32 positions of the combined array
  combinedArray.set(stringBytes.slice(0, 32));

  // Copy the encodedBiosensorSignalMap (int8Array) into the last 64 positions of the combined array
  let encodedBiosensorSignalMap = encodeBiosensorSignalMap(spotsgrid1d);
  combinedArray.set(encodedBiosensorSignalMap, 32);
  return combinedArray;
};

export const csmMosiStartSampling = (): Uint8Array => {
  return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.StartSampling);
};

export const csmMosiStopSampling = (): Uint8Array => {
  return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.StopSampling);
};

export const csmMosiGetVersions = (): Uint8Array => {
  return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.GetVersions);
};

export const csmMosiSetReference = (): Uint8Array => {
  return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.SetReference);
};

export const csmMosiGetBiosensorSignalMap = (): Uint8Array => {
  return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap);
};

export const encodePumpPower = (pumpPower: number): Uint8Array => {
  let data = new Uint8Array(2);
  let view = new DataView(data.buffer);
  view.setUint16(0, pumpPower, true);
  return data;
};

export const decodeArduinoMsgCutEvent = (data: Uint8Array): ArduinoMsgCutEvent => {
  let view = new DataView(data.buffer);
  let decodedEvent: ArduinoMsgCutEvent = {
    PeripheralAddress: view.getUint8(0),
    Type: view.getUint8(1),
    MsgId: view.getUint8(2),
    MsgLength: view.getInt16(3, true),
    NbPackets: view.getInt16(5, true),
    State: view.getUint8(7),
  };
  return decodedEvent;
};
