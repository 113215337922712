import { FC, useEffect, useRef, useState } from 'react';
import { Typography } from 'antd';
import 'uplot/dist/uPlot.min.css';
import { FlexCol, FlexRow, Paper } from '../../components/common/common';
import MziChart from '../../components/widgets/ChemicalFamilyIdentification/MziChart';
import { useMessageContext } from '../../state/context/MessageContext';
import { SignatureWithSpotgrid } from '../../types/types';
import { DeviceValue } from '../../services/cache/idb';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { v4 as uuidv4 } from 'uuid';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE } from '../../components/serial/csm';
import { loadGenericModel, loadHumidityCalibrant, loadSpotsGrid1D, loadSpotsGrid1DwithPeptide } from '../../services/cache/localStorage';
import { parseBiosensorsSignalMessagePayload, shouldCompute, updateKineticSeries } from '../../components/analysis/compute';
import { processMziData } from '../../components/analysis/mzi';
import DataProcessor from '../../components/widgets/ChemicalFamilyIdentification/DataProcessor';
import BubbleChart from '../../components/widgets/ChemicalFamilyIdentification/BubbleChart';
import { mean } from '../../components/analysis/utils';

const { Text } = Typography;

interface PeptideContent {
  peptide: string;
  ratio: number;
}

type MZIPackage = {
  rawMZI: number[],
  rawMZIwithHumidityCorrection: number[],
}

export interface SecondData {
  id: string;
  content: PeptideContent[];
}

type BleOdorIdentificationProps = {};
enum QuestioningState {
  SensorCleaning = 'SensorCleaning',
  BaselineRecording = 'BaselineRecording',
  AnalyteRecording = 'AnalyteRecording',
  GenericDisplay = 'GenericDisplay',
  OdorAnalysis = 'OdorAnalysis',
  OdorDisplay = 'OdorDisplay',
}

enum SenseMode {
  Recording,
  Questionning,
}

const CsmChemicalFamilyIdentificationPage: FC = () => {
  // const [currentData, setCurrentData] = useState<SecondData[]>();
  // const [selectedJsonName, setSelectedJsonName] = useState<string | null>(null);
  // // const [jsonData, setJsonData] = useState<any | null>(null);
  // const [currentProba, setCurrentProba] = useState<SecondData[]>([]);
  // const [currentChemicalFamily, setCurrentChemicalFamily] = useState<string>('');

  // // * plot tooling
  // const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});
  // const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  // const [currentSpotsgrid1dWithPetpide, setCurrentSpotsgrid1dWithPetpide] = useState<number[] | null>(null);

  // const finalRawMZI = useRef<number[]>([]);
  // const seriesLabelsAsProps = useRef<number[]>([]);

  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  const { csmMessages, csmFwVersion, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  // * humidity compensation
  const [humidityCalibrant, setHumidityCalibrant] = useState<SignatureWithSpotgrid | undefined>(undefined);
  const humidityBaselineRef = useRef<number | null>(null);
  const [humidityCompensationEnabled, sethumidityCompensationEnabled] = useState<boolean>(false);
  // *

  // * MZI and timestamps related useRefs
  const firstMZIsRef = useRef<number[] | null>(null);
  const previousMZIsRef = useRef<number[] | null>(null);
  const signalEnvelopeAvgRef = useRef<number>(0);
  const signalMZI = useRef<number[]>([]);

  const rawMZISeriesRef = useRef<number[][]>([]);
  const averageMZISeriesRef = useRef<number[]>([]);
  const isMZIcomputingrequested = useRef<boolean>(false);

  // * HIH useRefs
  const humidityDisplayed = useRef<number>(0);
  const temperatureDisplayed = useRef<number>(0);
  const { saveHihValue } = useMessageContext();

  // *

  // * ALGO
  //
  const decimatedMZISeriesCorrected = useRef<number[][]>([]);

  const chartData = useRef<number[] | null>(null);
  const [mziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null);

  const [mziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([]);
  const [fpsUplotData, setFpsUplotData] = useState<uPlot.AlignedData>([]);

  const mziTargetRef = useRef<HTMLDivElement>(null);
  const mziUplotRef = useRef<uPlot | null>(null);
  const mziTooltipRef = useRef<HTMLDivElement>(null);

  const fpsTargetRef = useRef<HTMLDivElement>(null);
  const fpsUplotRef = useRef<uPlot | null>(null);

  const KsRef = useRef<number[] | null>(null);

  const decimatedMZISeriesRef = useRef<number[][]>([]);
  const analyteMZISeriesRef = useRef<number[][]>([]);

  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const rawTimestampSeriesRef = useRef<number[]>([]);

  const decimatedMZIPartitionSeriesRef = useRef<number[][]>([]);
  const decimatedTimestampPartitionSeriesRef = useRef<number[]>([]);

  const decimatedFpsTimeseriesRef = useRef<number[]>([]);
  const rawFpsTimeseriesRef = useRef<number[]>([]);

  const lastDecimationTickRef = useRef<number>(0);

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [currentSpotsgrid1dWithPeptide, setCurrentSpotsgrid1dWithPeptide] = useState<number[] | null>(null);
  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isSensing, setIsSensing] = useState<boolean>(true);

  const [recordStartTimestamp, setRecordStartTimestamp] = useState<number>(0);

  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);

  const [shouldAggregate, setShouldAggregate] = useState<boolean>(true);

  const [shouldRedraw, setShouldRedraw] = useState<boolean>(false);

  const [currentProba, setCurrentProba] = useState<SecondData[]>([]);
  const [currentChemicalFamily, setCurrentChemicalFamily] = useState<string>('unknown');

  const [debugDataForDisplay, setDebugDataForDisplay] = useState<MZIPackage>({ rawMZI: [], rawMZIwithHumidityCorrection: [] });

  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));
  const seriesLabelsAsProps = useRef<number[]>([]);

  // const [currentModel, setCurrentModel] = useState<GenericModel | null>(null);
  // const [currentModel, setCurrentModel] = useState<GenericModel | null>(null); back to image display for September demo

  // reset MZI for display centered around 0
  const onClickReset = () => {
    // previousMZIsRef.current = null is to avoid synthetic phase jumps when signal drops from some value > pi/2 to zero
    previousMZIsRef.current = null;
    firstMZIsRef.current = null;
  };

  const setDataForDisplay = (dataForDisplay: MZIPackage) => {
    setDebugDataForDisplay(dataForDisplay)

  }

  const toggleHumidityCompensation = (humidityCompensationEnabled: boolean) => {
    sethumidityCompensationEnabled(!humidityCompensationEnabled)
  }

  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    let _spotsGrid1DwithPeptide = loadSpotsGrid1DwithPeptide();
    console.log('_spotsgrid1d', _spotsgrid1d);
    console.log('_spotsGrid1DwithPeptide', _spotsGrid1DwithPeptide);
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
    setCurrentSpotsgrid1dWithPeptide(_spotsGrid1DwithPeptide);
    // Aggregate MZIs by peptide
    let _aggregationIndicesMap: Record<number, number[]> = {};
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let aggKey = _spotsgrid1d[i];
      if (aggKey < 0) {
        continue;
      }
      if (_aggregationIndicesMap[aggKey] === undefined) {
        _aggregationIndicesMap[aggKey] = [];
      }
      _aggregationIndicesMap[aggKey].push(i);
    }
    setAggregatedIndicesMap(_aggregationIndicesMap);
  }, []);

  useEffect(() => {
    if (csmMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }

    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        let nFramesProcessed = 0;

        csmMessages.forEach((message) => {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('sense page: csm ble message is not a biosensors signal event', message.message);
            consumeCSMMessage(message.id);
            return;
          } else {
            nFramesProcessed++;
            consumeCSMMessage(message.id);

            // Parse message payload
            let mzis = parseBiosensorsSignalMessagePayload(message, csmFwVersion);

            if (!mzis) return;

            // set Baseline delta H for future use
            if (humidityBaselineRef.current === null) {
              console.log('Changing humidity baseline reference as ', hihValues.humidity);
              humidityBaselineRef.current = hihValues.humidity;
            }

            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...mzis];
            }
            rawMZISeriesRef.current.push(mzis);
            isMZIcomputingrequested.current = shouldCompute(message.ts, lastDecimationTickRef); // shouldCompute is in fact decimation
            // console.log('arguments:', mzis, rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef.current, hihValues)
            let [averageMZI, rawMzis, correctedMZI] = processMziData(rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef.current, hihValues) || [0, []];
            // console.log('raw and correctedMZI:', rawMzis, correctedMZI);

            // updateKineticSeries(averageMZI, correctedMZI, averageMZISeriesRef, decimatedMZISeriesCorrected);

            if (isMZIcomputingrequested.current && correctedMZI) {
              // to prevent frequent rendereing
              // console.log('averageMZI...', averageMZI, ' humidity correction is', humidityCompensationEnabled, 'lastDecimationTickRef.current is', lastDecimationTickRef.current)

              // Update displayed values
              signalEnvelopeAvgRef.current = averageMZI; // for display in the component
              signalMZI.current = correctedMZI;
              // console.log('corrected MZI mean', signalMZI.current);
              humidityDisplayed.current = hihValues.humidity;
              temperatureDisplayed.current = hihValues.temperature;

              chartData.current = signalMZI.current;

              setDebugDataForDisplay({
                rawMZI: rawMzis,
                rawMZIwithHumidityCorrection: correctedMZI
              })
            }
          }
        });
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        rawMZISeriesRef.current = []; // flushing
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  useEffect(() => {
    // if (questioningState !== QuestioningState.OdorAnalysis) return;
    let _model = loadGenericModel();
    console.log(' Selected Model is :', _model);
    // let _model = loadModel();
    // setSignatureWindowValue(_model?.analyteDuration || DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
    // setThresholdOverride(_model?.comparisonThreshold || DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);
    // setCurrentModel(_model);
  }, []);

  // load humidity calibrant
  useEffect(() => {
    const hCalibrant = loadHumidityCalibrant();
    setHumidityCalibrant(hCalibrant);
    console.log('humidityCalibrant is', hCalibrant);
  }, []);

  return (
    <FlexCol
      style={{
        justifyContent: 'center',
        alignItems: 'start',
        width: '100%',
      }}
    >
      {chartData.current && (
        <MziChart
          aggregatedIndicesMap={aggregatedIndicesMap}
          currentSpotsgrid1d={currentSpotsgrid1d}
          currentSpotsgrid1dWithPeptide={currentSpotsgrid1dWithPeptide}
          finalRawMZI={chartData}
          seriesLabelsAsProps={seriesLabelsAsProps}
          onClickReset={onClickReset}
          setDataForDisplay={setDataForDisplay}
          toggleHumidityCompensation={toggleHumidityCompensation}
        />
      )}

      {chartData.current && chartData.current.length > 0 && <DataProcessor rawSignalFromApp={debugDataForDisplay.rawMZI} correctedSignalFromApp={debugDataForDisplay.rawMZIwithHumidityCorrection} seriesLabels={currentSpotsgrid1d} setCurrentProba={setCurrentProba} setCurrentChemicalFamily={setCurrentChemicalFamily} />}
      {currentProba && currentProba.length >= 1 && chartData.current !== null && (
        <Paper
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <BubbleChart data={currentProba} currentChemicalFamily={currentChemicalFamily} />
        </Paper>
      )}
    </FlexCol>
  );
};

export default CsmChemicalFamilyIdentificationPage;
