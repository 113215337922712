import { getEllipseSVGPath } from './ellipse';
import { colorTupleToRGBA } from '../../utils/helpers/utils';
import { transpose } from './utils';
import { signature } from './definitions';
import { DEFAULT_PLOTLY_LAYOUT, DEFAULT_PLOTLY_MARGIN, PEAK_RADIUS } from '../../utils/constants/constants';
import { PeakPositionsType } from '../serial/csm';
import { Shape } from 'plotly.js';

export const getPcaFigure = (cmap: Record<string, [number, number, number]>, pcaExplainedVarianceRatio: number[], groupedProjections: Record<string, number[][]>, scaledGroupedEllipses: Record<string, number[]>) => {
  const shapes: any[] = [];
  Object.entries(scaledGroupedEllipses).forEach(([group, ellipse]) => {
    const [cx, cy, a, b, theta] = ellipse;
    const path = getEllipseSVGPath(cx, cy, a, b, theta);
    shapes.push({
      type: 'path',
      path,
      fillcolor: colorTupleToRGBA(cmap[group], 0.1),
      line: {
        width: 1,
        color: colorTupleToRGBA(cmap[group], 1),
      },
    });
  });

  const data: Plotly.Data[] = [];
  const layout: Partial<Plotly.Layout> = {
    hovermode: 'closest',
    xaxis: {
      title: {
        text: 'PCA 1 (' + (100 * pcaExplainedVarianceRatio[0]).toFixed(1) + '%)',
        font: { size: 12 },
      },
      showticklabels: false,
    },
    yaxis: {
      title: {
        text: 'PCA 2 (' + (100 * pcaExplainedVarianceRatio[1]).toFixed(1) + '%)',
        font: { size: 12 },
      },
      scaleanchor: 'x',
      scaleratio: 1,
      showticklabels: false,
    },
    annotations: [],
    dragmode: 'zoom',
    shapes,
  };
  Object.entries(groupedProjections).forEach(([group, projs]) => {
    data.push({
      type: 'scatter',
      name: group,
      legendgroup: group,
      x: projs.map((e) => e[0]),
      y: projs.map((e) => e[1]),
      mode: 'markers',
      hoverinfo: 'text',
      text: projs.map((e, i) => `${group}_${i + 1}`),
      marker: {
        size: 10,
        line: {
          color: colorTupleToRGBA(cmap[group], 1),
          width: 2,
        },
        color: colorTupleToRGBA(cmap[group], 0.1),
      },
    });
  });
  return { data, layout };
};

export const getModeledPcaFigure = (cmap: Record<string, [number, number, number]>, groupedScaledEllipses: Record<string, number[]>) => {
  const shapes: any[] = [];
  Object.entries(groupedScaledEllipses).forEach(([group, ellipse]) => {
    const [cx, cy, a, b, theta] = ellipse;
    const path = getEllipseSVGPath(cx, cy, a, b, theta);
    shapes.push({
      type: 'path',
      path,
      fillcolor: colorTupleToRGBA(cmap[group], 0.1),
      line: {
        width: 1,
        color: colorTupleToRGBA(cmap[group], 1),
      },
    });
  });

  const data: Plotly.Data[] = [];

  let _minX = 1e6;
  let _maxX = -1e6;
  let _minY = 1e6;
  let _maxY = -1e6;

  Object.values(groupedScaledEllipses).forEach((ellipse) => {
    const [cx, cy, a, b, theta] = ellipse;
    let r = Math.sqrt(a * a + b * b);
    if (cx - r < _minX) _minX = cx - r;
    if (cx + r > _maxX) _maxX = cx + r;
    if (cy - r < _minY) _minY = cy - r;
    if (cy + r > _maxY) _maxY = cy + r;
  });

  const layout: Partial<Plotly.Layout> = {
    hovermode: 'closest',
    xaxis: {
      title: {
        text: 'PCA 1',
        font: { size: 9 },
      },
      range: [_minX, _maxX],
      showticklabels: false,
    },
    yaxis: {
      title: {
        text: 'PCA 2',
        font: { size: 9 },
      },
      scaleanchor: 'x',
      scaleratio: 1,
      range: [_minY, _maxY],
      showticklabels: false,
    },
    annotations: [],
    dragmode: 'zoom',
    shapes,
  };
  return { data, layout };
};

export const addPointToPcaFigure = (
  pcaFig: {
    data: Plotly.Data[];
    layout: Partial<Plotly.Layout>;
  },
  point: [number, number],
  label: string,
  cmap: Record<string, [number, number, number]>
) => {
  let color = 'red';
  if (Object.keys(cmap).includes(label)) {
    color = colorTupleToRGBA(cmap[label], 1);
  }
  pcaFig.data.push({
    type: 'scatter',
    name: label,
    legendgroup: 'test_points',
    x: [point[0]],
    y: [point[1]],
    mode: 'markers',
    marker: {
      color,
      size: 10,
      symbol: 'square',
    },
  });
  return pcaFig;
};

export const getSignaturesTable = (spotfile: string[], signatures: signature[], numericLabels: number[]): { data: any[]; layout: any } => ({
  data: [
    {
      type: 'table',
      header: {
        values: spotfile.concat(['label']),
        fill: {
          color: Array(signatures[0].length).fill('eee').concat(['#1193f5']),
        },
      },
      cells: {
        values: transpose(signatures.map((e, i) => e.concat([numericLabels[i]]))),
        format: Array(signatures[0].length).fill('.4f').concat([null]),
      },
    },
  ],
  layout: {},
});

export const getSignaturesFigure = (spotsgrid1d: number[], signatures: signature[], labels: string[], cmap: Record<string, [number, number, number]>) => {
  // Sort spots in nature ascending order
  // const { sortedSpotfile, sortedSignatures } = sortSignatures(spotfile, signatures);
  let spotfileStr = spotsgrid1d.map((e) => '&nbsp;' + e.toString());
  let data: Plotly.Data[] = signatures.map((sig, i) => ({
    type: 'scatterpolar',
    name: `${labels[i]}_${i + 1}`,
    legendgroup: labels[i],
    theta: spotfileStr.concat(spotfileStr.slice(0, 1)),
    r: sig.concat(sig.slice(0, 1)),
    line: {
      color: colorTupleToRGBA(cmap[labels[i]], 1),
    },
  }));

  let _min: number = 1e6;
  let _max: number = -1e6;
  signatures.forEach((sig) => {
    sig.forEach((e) => {
      if (e < _min) _min = e;
      if (e > _max) _max = e;
    });
  });

  let layout: Partial<Plotly.Layout> = {
    polar: {
      angularaxis: {
        // range: [_min, _max],
        showgrid: false,
        showline: true,
        showticklabels: true,
        ticks: '',
      },
      radialaxis: {
        range: [_min, _max],
        showgrid: true,
        showline: true,
        showticklabels: true,
        ticks: 'outside',
      },
    },
  };
  return {
    data,
    layout,
  };
};

export const getSignatureFigure = (spotsgrid1d: number[], signature: signature, color?: string) => {
  let spotfileStr = spotsgrid1d.map((e) => '&nbsp;' + e.toString());
  let signatureFixed = signature.map((e) => parseFloat(e.toFixed(3)));
  let data: Plotly.Data[] = [
    {
      type: 'scatterpolar',
      theta: spotfileStr.concat(spotfileStr.slice(0, 1)),
      r: signatureFixed.concat(signatureFixed.slice(0, 1)),
      fill: 'toself',
      hoverinfo: 'y+x',
      hoveron: 'points',
      line: {
        color: color || '#202A44',
      },
    },
  ];

  let _min = Math.min(...signatureFixed);
  let _max = Math.max(...signatureFixed);

  let layout: Partial<Plotly.Layout> = {
    polar: {
      angularaxis: {
        showgrid: false,
        showline: true,
        showticklabels: true,
        ticks: '',
      },
      radialaxis: {
        range: [_min, _max],
        showgrid: true,
        showline: true,
        showticklabels: true,
        ticks: 'outside',
      },
    },
    margin: {
      t: 20,
      b: 20,
      l: 30,
      r: 30,
    },
  };
  return {
    data,
    layout,
  };
};

export const getFigureFromGenericModel = (distances: { [key: string]: number }, color?: string) => {
  // Extract the keys into an array of strings
  // const classesFromModel: string[] = Object.keys(distances);
  //const computedProbabilities: number[] = Object.values(distances).map(value => parseFloat(value.toFixed(2)));

  // convert to array
  var distancesArray = Object.keys(distances).map((key) => {
    return { key: key, value: distances[key] };
  });

  distancesArray.sort((a, b) => a.value - b.value);

  let computedProbabilities = distancesArray.map((x) => x.value);
  let classesFromModel = distancesArray.map((x) => x.key);

  let data: Plotly.Data[] = [
    {
      type: 'bar',
      x: computedProbabilities,
      y: classesFromModel,
      orientation: 'h',
      hoverinfo: 'y+x',
      hoveron: 'points',
      line: {
        color: color || '#202A44',
      },
    },
  ];

  let _min = Math.min(...computedProbabilities);
  let _max = Math.max(...computedProbabilities);

  let layout: Partial<Plotly.Layout> = {
    polar: {
      angularaxis: {
        showgrid: false,
        showline: true,
        showticklabels: true,
        ticks: '',
      },
      radialaxis: {
        range: [_min, _max],
        showgrid: true,
        showline: true,
        showticklabels: true,
        ticks: 'outside',
      },
    },
    margin: {
      t: 20,
      b: 20,
      l: 100,
      r: 30,
    },
  };
  return {
    data,
    layout,
  };
};

/**
 * Generates a Plotly figure from a 2D array of image data.
 *
 * This function processes a 2D array of numbers representing an image's pixel data and returns an object containing both:
 *   - `data` (the plot data for rendering the image)
 *   - `layout` (the layout configuration for the Plotly figure).
 *
 * Optionally, you can choose whether to copy the underlying image data.
 *
 * @param {number[][]} imageArray - A 2D array representing the image's pixel data, where each entry in the
 *                                   array corresponds to a pixel's intensity in the range 0-255.
 *                                   This could be an array of grayscale intensities or color values (like RGB).
 * @param {boolean} [copyUnderlyingArray=true] - A flag indicating whether to create a copy of the `imageArray` or use it directly.
 *                                               If true, a new copy of the image array will be made, which will always trigger a rendering of the plot;
 *                                               otherwise, the original array is used and in that case no Plot rendering will be triggered !
 *
 * @returns {{ data: Plotly.Data[]; layout: Partial<Plotly.Layout>; }} An object containing the following:
 *   - `data`: An array of Plotly data objects for rendering the image. This will typically include
 *             a heatmap plot based on the `imageArray`.
 *   - `layout`: A partial configuration object for the layout of the Plotly figure, which can include
 *              title, axis labels, and other display settings.
 */
export const getImageFigure = (imageArray: number[][], peakPositions: PeakPositionsType | undefined = undefined, copyUnderlyingArray = true) => {
  // Placeholder to hold the Plotly data object
  let data: Plotly.Data[] = [
    {
      z: copyUnderlyingArray ? [...imageArray] : imageArray, // Copy image data if flag is true
      colorscale: 'Greys', // blackscale image is being plotted here
      type: 'heatmap', // Define the plot type as a heatmap, suitable for displaying image data
      zmin: 0,
      zmax: 255,
      showscale: false,
    },
  ];

  const shapes: Partial<Shape>[] = [];
  if (peakPositions !== undefined) {
    // data.push({
    //   x: peakPositions.x,
    //   y: peakPositions.y,
    //   type: 'scatter',
    //   text: new Array(192).fill('t'),
    //   textfont: {
    //     size: 10,
    //     color: 'white',
    //   },
    //   textposition: 'top center',
    //   mode: 'text+markers',
    //   marker: {
    //     size: 3,
    //   },
    // });

    for (let ipeak = 0; ipeak < peakPositions.x.length; ipeak++) {
      let x = peakPositions.x[ipeak];
      let y = peakPositions.y[ipeak];
      // spot = ipeak // 3
      // phase = ipeak % 3
      // peak_names.append(spot_order_to_coordinate(spot, phase))
      shapes.push({
        type: 'circle',
        xref: 'x',
        yref: 'y',
        x0: x - PEAK_RADIUS,
        y0: y - PEAK_RADIUS,
        x1: x + PEAK_RADIUS,
        y1: y + PEAK_RADIUS,
        line: {
          width: 1,
          color: 'yellow',
        },
      });
    }
  }

  let layout: Partial<Plotly.Layout> = {
    xaxis: {
      showticklabels: false,
      side: 'top',
      ticks: '',
      title: 'x (pixel)',
    },
    yaxis: {
      autorange: 'reversed',
      showticklabels: false,
      ticks: '',
      title: 'y (pixel)',
    },
    margin: DEFAULT_PLOTLY_MARGIN,
    showlegend: false,
    shapes: shapes,
  };

  return { data, layout };
};
