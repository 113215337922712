import { Col, InputNumber, InputNumberProps, Row, Slider, Switch, Tag, Typography } from 'antd';
import { FlexRow, Paper } from '../../../components/common/common';
import { GenericModel } from '../../../components/analysis/definitions';

type GlobalParametersComponentProps = {
  showGlobalParameters: boolean;
  signatureWindowValue: number;
  setSignatureWindowValue: React.Dispatch<React.SetStateAction<number>>;
  thresholdOverride: number;
  setThresholdOverride: React.Dispatch<React.SetStateAction<number>>;
  currentModel: GenericModel | null;
  setCurrentModel: React.Dispatch<React.SetStateAction<GenericModel | null>>;
  noizeLevelRef: React.MutableRefObject<number>;
  humidityCompensationEnabled: boolean;
  sethumidityCompensationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  stdHihThreshold: number;
  setStdHihThreshold: React.Dispatch<React.SetStateAction<number>>;
  stdHihStable: number;
  setStdHihStable: React.Dispatch<React.SetStateAction<number>>;
  hihBufferLength: number;
  setHihBufferLength: React.Dispatch<React.SetStateAction<number>>;
  analyteHihBufferLength: number;
  setAnalyteHihBufferLength: React.Dispatch<React.SetStateAction<number>>;
  currentStdHihAnalyteDisplay: React.MutableRefObject<number>;
  distanceDisplay: React.MutableRefObject<number>;


};

const GlobalParametersIdentificationComponent: React.FC<GlobalParametersComponentProps> = ({
  showGlobalParameters,
  signatureWindowValue,
  setSignatureWindowValue,
  thresholdOverride,
  setThresholdOverride,
  currentModel,
  setCurrentModel,
  noizeLevelRef,
  humidityCompensationEnabled,
  sethumidityCompensationEnabled,
  stdHihThreshold,
  setStdHihThreshold,
  stdHihStable,
  setStdHihStable,
  hihBufferLength,
  setHihBufferLength,
  analyteHihBufferLength,
  setAnalyteHihBufferLength,
  currentStdHihAnalyteDisplay,
  distanceDisplay
}) => {
  const onSignatureWindowSliderChange: InputNumberProps['onChange'] = (newValue) => {
    setSignatureWindowValue(newValue as number);
  };

  const onHihThresholdSliderChange: InputNumberProps['onChange'] = (newValue) => {
    setStdHihThreshold(newValue as number);
  };

  const onHihStableSliderChange: InputNumberProps['onChange'] = (newValue) => {
    setStdHihStable(newValue as number);
  };

  const onHihBufferLengthSliderChange: InputNumberProps['onChange'] = (newValue) => {
    setHihBufferLength(newValue as number);
  };

  const onAnalyteHihBufferLengthSliderChange: InputNumberProps['onChange'] = (newValue) => {
    setAnalyteHihBufferLength(newValue as number);
  };

  const onComparisonThresholdChange: InputNumberProps['onChange'] = (newValue) => {
    if (newValue !== null) {
      setThresholdOverride(newValue as number);
      if (currentModel !== null) {
        setCurrentModel({
          ...currentModel,
          comparisonThreshold: newValue as number,
        });
      }
    }
  };

  return (
    <>
      {showGlobalParameters && (
        <Paper style={{ width: '100%', marginTop: 50, marginBottom: 30 }}>
          <Typography.Title level={5} style={{ margin: 'auto', textAlign: 'center', width: '100%', marginBottom: 30 }}>
            Global parameters
          </Typography.Title>

          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Current model</Col>
            <Col span={12}>{currentModel?.metadata.ID}</Col>
            <Col span={4}></Col>
          </Row>
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Analyte duration</Col>
            <Col span={12}>
              <Slider min={1} max={20} onChange={onSignatureWindowSliderChange} value={typeof signatureWindowValue === 'number' ? signatureWindowValue : 0} />
            </Col>
            <Col span={4}>
              <InputNumber min={1} max={20} value={signatureWindowValue} onChange={onSignatureWindowSliderChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: 15, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Comparison Threshold</Col>
            <Col span={12}>
              <InputNumber min={0} value={thresholdOverride} onChange={onComparisonThresholdChange} />
            </Col>
            <Col span={4}></Col>
          </Row>
          {currentModel?.metadata.debug && (
            <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
              <Col span={6}>Noise level</Col>
              <Col span={12} style={{ fontWeight: 'bold' }}>
                {noizeLevelRef.current.toFixed(2)}
              </Col>
              <Col span={4}></Col>
            </Row>

          )}
          {currentModel?.metadata.debug && (
            <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
              <Col span={6}>Current HIH analyte Stability</Col>
              <Col span={12} style={{ fontWeight: 'bold' }}>
                {currentStdHihAnalyteDisplay.current.toFixed(2)}
              </Col>
              <Col span={4}></Col>
            </Row>

          )}
          {currentModel?.metadata.debug && (
            <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
              <Col span={6}>Distance with water calibrant</Col>
              <Col span={12} style={{ fontWeight: 'bold' }}>
                {distanceDisplay.current.toFixed(2)}
              </Col>
              <Col span={4}></Col>
            </Row>

          )}
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Humidity correction enabled</Col>
            <Col span={12}>
              <Switch onChange={sethumidityCompensationEnabled} defaultChecked={humidityCompensationEnabled} />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Hih Threshold trig</Col>
            <Col span={12}>
              <Slider min={0} max={10} onChange={onHihThresholdSliderChange} value={typeof stdHihThreshold === 'number' ? stdHihThreshold : 0} />
            </Col>
            <Col span={4}>
              <InputNumber min={0} max={10} value={stdHihThreshold} onChange={onHihThresholdSliderChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Hih Stable value trig</Col>
            <Col span={12}>
              <Slider min={1} max={20} onChange={onHihStableSliderChange} value={typeof stdHihStable === 'number' ? stdHihStable : 0} />
            </Col>
            <Col span={4}>
              <InputNumber min={1} max={20} value={stdHihStable} onChange={onHihStableSliderChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Hih baseline buffer length</Col>
            <Col span={12}>
              <Slider min={1} max={20} onChange={onHihBufferLengthSliderChange} value={typeof hihBufferLength === 'number' ? hihBufferLength : 0} />
            </Col>
            <Col span={4}>
              <InputNumber min={1} max={20} value={hihBufferLength} onChange={onHihBufferLengthSliderChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Hih Analyte buffer length</Col>
            <Col span={12}>
              <Slider min={1} max={20} onChange={onAnalyteHihBufferLengthSliderChange} value={typeof analyteHihBufferLength === 'number' ? analyteHihBufferLength : 0} />
            </Col>
            <Col span={4}>
              <InputNumber min={1} max={20} value={analyteHihBufferLength} onChange={onAnalyteHihBufferLengthSliderChange} />
            </Col>
          </Row>
        </Paper>
      )}
    </>
  );
};

export default GlobalParametersIdentificationComponent;
