import { Col, Row } from 'antd';
import SectionPage from '../../../components/widgets/section/SectionPage';
import { IntensityGauge } from '../../../components/widgets/Gauges/IntensityGauge';
import { HIHvalues } from '../../../types/types';
import { Paper } from '../../../components/common/common';

interface BaselineRecordingProps {
  MZIvalue: number;
  hihValues?: HIHvalues;
}

const BaselineRecording: React.FC<BaselineRecordingProps> = ({ MZIvalue, hihValues }) => {
  return (
    <Paper>
      <Col span={24}>
        <IntensityGauge MZIvalue={MZIvalue} hihValues={hihValues} />
        <Row justify="center">
          <Col span={20}>
            <h1 style={{ textAlign: 'center', fontSize: 20, lineHeight: '30px', marginTop: 20, marginBottom: 20 }}>Please Wait...</h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={20} style={{ padding: 20, borderRadius: 20, textAlign: 'center' }}>
            <p style={{ fontSize: 16, lineHeight: '22px' }}>Recording baseline...</p>
          </Col>
        </Row>
      </Col>
    </Paper>
  );
};

export default BaselineRecording;
