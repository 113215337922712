import { Col, InputNumber, InputNumberProps, Row, Slider, Switch, Typography } from 'antd';
import { FlexRow, Paper } from '../../../components/common/common';

type GlobalParametersComponentProps = {
  showGlobalParameters: boolean;
  pumpPower: number;
  setPumpPower: React.Dispatch<React.SetStateAction<number>>;
  isGraphQlPushRunning: boolean;
  setIsGraphQlPushRunning: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalParametersComponent: React.FC<GlobalParametersComponentProps> = ({ showGlobalParameters, pumpPower, setPumpPower, isGraphQlPushRunning, setIsGraphQlPushRunning }) => {
  const onPumpPowerSliderChange: InputNumberProps['onChange'] = (newValue) => {
    let x = newValue as number;
    setPumpPower(x);
  };

  const toggleSwitch = (checked: boolean) => {
    setIsGraphQlPushRunning(checked);
  };

  return (
    <>
      {showGlobalParameters && (
        <Paper style={{ width: '100%', height: '100%', marginTop: 50, marginBottom: 30 }}>
          <Typography.Title level={5} style={{ margin: 'auto', textAlign: 'center', width: '100%', marginBottom: 30 }}>
            Global parameters
          </Typography.Title>

          <Row justify="center">
            <Col span={24}>
              Pump power (mW)
              <Row style={{ marginTop: 5, width: '100%' }}>
                <Col span={16}>
                  <Slider min={0} max={1000} onChange={onPumpPowerSliderChange} value={typeof pumpPower === 'number' ? pumpPower : 0} />
                </Col>
                <Col span={8}>
                  <InputNumber min={0} max={1000} style={{ margin: '0 16px' }} value={pumpPower} onChange={onPumpPowerSliderChange} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 30 }}>
            <Col span={24}>
              GraphQL push
              <Col span={24} style={{ marginTop: 10 }}>
                <Switch checked={isGraphQlPushRunning} onChange={toggleSwitch} />
              </Col>
            </Col>
          </Row>
        </Paper>
      )}
    </>
  );
};

export default GlobalParametersComponent;
